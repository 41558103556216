import React, { useEffect, useState } from 'react';
import MiniStatistics from "./components/MiniStatistics";
import { MdOutlineBarChart, MdPerson, MdFileCopy, MdSchool } from "react-icons/md";
import UserReportsTable from "./components/UserReportsTable";
import { columnsDataUserReports } from "./variables/columnsDataUserReports";
import { getUserProfile, fetchTeamMembers, fetchTeamRankings, fetchCurrentDescription } from "api";

const Application = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const [userProfile, setUserProfile] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [rankedTeams, setRankedTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);



  // Fetch data when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const profile = await getUserProfile();
        const teamId = profile.user.team?.id;
        console.log('User Profile:', profile);
  
        if (teamId) {
          let members = await fetchTeamMembers(teamId);
          console.log('Team Members:', members);
          // Filter out the logged-in user
          members = members.filter(member => member.id !== profile.user.id);
  
          // Transform the data to match the columns' accessors
          const formattedMembers = members.map(member => ({
            name: member.username,
            email: member.email,
            teamRole: member.teamRole,
            status: member.status,
          }));
  
          setTeamMembers(formattedMembers);
          setSelectedTeam(profile.user.team);
  
          // Fetch current description ID and project name
          const { descriptionId, projectName } = await fetchCurrentDescription(teamId);
          console.log("Fetched Current Description:", { descriptionId, projectName });
  
          // Update the selected team with the current project name
          setSelectedTeam(prevTeam => ({
            ...prevTeam,
            currentProject: projectName
          }));
        }
  
        const teams = await fetchTeamRankings();
        setRankedTeams(teams);
        setUserProfile(profile);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Failed to load data');
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);

  const schoolName = userProfile?.user?.school || 'Loading ...';

if (loading) return <p>Loading...</p>;
if (error) return <p>{error}</p>;

const teamRank = selectedTeam 
  ? rankedTeams.findIndex(team => team.id === selectedTeam.id) + 1
  : "N/A";

    console.log('Selected Team:', selectedTeam); // Debugging line
    console.log('School Name:', schoolName); // Debugging 

    if (loading) return <p>Loading...</p>
    if (error) return <p>{error}</p>

    

  return (
    <div className="mt-3 h-full w-full rounded-[20px]">
      <div className="grid grid-cols-1 gap-5 md:grid-cols-2 3xl:grid-cols-4">
        <div>
          <MiniStatistics
            icon={<MdOutlineBarChart className="text-4xl" />}
            title="Team Rank"
            value={selectedTeam ? `${teamRank}th` : "Not Assigned"} 
            growth={`${selectedTeam ? selectedTeam.points : 0} Club Points`}
            growthColor="text-green-500"
            cardBg="bg-gradient-to-r from-orange-400 to-orange-600"
            titleColor="text-white"
            valueColor="text-white"
            detailColor="text-white"
            iconColor="text-white"
          />
        </div>
        <div>
          <MiniStatistics
            icon={<MdFileCopy className="text-4xl" />}
            title="Current Project"
            value={selectedTeam ? selectedTeam.currentProject : "Not Available"} 
            bgColor="bg-white dark:!bg-navy-800"
            growth="Phase 3"
            growthColor="text-blue-500"
            cardBg="bg-white"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white"
            detailColor="text-gray-600"
            iconColor="text-brand-500"
          />
        </div>
        <div>
          <MiniStatistics
            icon={<MdSchool className="text-4xl" />}
            title="School Name"
            value={schoolName}
            bgColor="bg-lightPrimary dark:!bg-navy-700"
            growth="Class of 2025"
            growthColor="text-purple-500"
            cardBg="bg-white"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white"
            detailColor="text-gray-600"
            iconColor="text-brand-500 dark:text-white"
          />
        </div>
        <div>
          <MiniStatistics
            icon={<MdPerson className="text-4xl" />}
            title="Mentor"
            value="Dr. Sarah Lee"
            bgColor="bg-lightPrimary dark:bg-navy-700"
            growth="Expert in Robotics"
            growthColor="text-red-500"
            cardBg="bg-white"
            titleColor="text-gray-600"
            valueColor="text-navy-700 dark:text-white"
            detailColor="text-gray-600"
            iconColor="text-brand-500 dark:text-white"
          />
        </div>
      </div>
      <div className="mt-3">
          <UserReportsTable
            tableData={teamMembers}
            columnsData={columnsDataUserReports}
            isModalOpen={isModalOpen}
            toggleModal={toggleModal}
            selectedTeam={selectedTeam}
            userProfile ={userProfile}
          />
       
      </div>
    </div>
  );
};

export default Application;
