// Custom components
import React from "react";

function InputField(props) {
  const { label, id, extra, type, placeholder, variant, state, disabled, value, onChange, error } =
    props;

  return (
    <div className={`${extra}`}>
      <label
        htmlFor={id}
        className={`block text-sm font-medium text-gray-700 dark:text-gray-300 mb-1 ${
          variant === "auth" ? "ml-3 font-medium text-navy-700" : "ml-1.5 font-medium"
        }`}
      >
        {label}
      </label>
      <input
        disabled={disabled}
        type={type}
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
          disabled === true
            ? "!border-none !bg-gray-100 dark:!bg-white/5 dark:placeholder:!text-[rgba(255,255,255,0.15)]"
            : state === "error"
            ? "border-red-500 text-red-500 placeholder:text-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400"
            : state === "success"
            ? "border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400"
            : "border-gray-200 dark:!border-white/10 dark:text-white"
        }`}
      />
      {error && (
        <p className="mt-1 text-sm text-red-500 dark:text-red-400 flex items-center">
          <svg
            className="w-4 h-4 mr-2 fill-current text-red-500 dark:text-red-400"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm1 15H9v-2h2v2zm0-4H9V5h2v6z" />
          </svg>
          {error}
        </p>
      )}
    </div>
  );
}

export default InputField;
