import React, { useEffect, useState } from 'react';
import Card from 'components/card';
import { FaRegClock, FaProjectDiagram, FaExclamationCircle } from 'react-icons/fa';
import { fetchNotifications } from 'api';
import { useNavigate } from 'react-router-dom';

const ChartSection = ({ mentorId }) => {
  const [approvals, setApprovals] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (mentorId) {
      fetchNotifications(mentorId)
        .then((notifications) => {
          const approvalRequests = notifications.filter(
            (notification) => notification.type === 'approval_request'
          );
          setApprovals(approvalRequests);
        })
        .catch((error) => {
          console.error('Error fetching notifications:', error);
        });
    }
  }, [mentorId]);

  const handleApprovalClick = (approval) => {
    // Extract relevant team, POAP, and step info from the approval to pass to ManageTeam
    const teamId = approval.teamId || approval.team?.id;
    const poapId = approval.poapId || approval.poap?.id;
    const stepId = approval.stepId || approval.step?.id;
    const categoryIndex = approval.categoryIndex || approval.category?.index;
    const stepIndex = approval.stepIndex || approval.step?.index;

    console.log('Navigating to ManageTeam with:', {
      selectedTeam: teamId,
      selectedPoap: poapId,
      selectedStep: stepId,
      categoryIndex,
      stepIndex,
    });

    navigate('/admin/manage-team', { 
        state: { 
            selectedTeam: teamId, 
            selectedPoap: poapId, 
            selectedStep: stepId,
            categoryIndex,
            stepIndex
        } 
    });
  };

  return (
    <Card extra="p-6 bg-white dark:bg-navy-800 shadow-lg rounded-[20px]">
      <h3 className="text-xl font-bold text-navy-700 dark:text-white mb-6 flex items-center">
        <FaExclamationCircle className="text-orange-500 mr-3" />
        Pending Approvals
      </h3>
      <div className="space-y-6">
        {approvals.length > 0 ? (
          approvals.map((approval) => (
            <div
              key={approval.id}
              className="flex items-start p-5 dark:bg-navy-700 rounded-lg shadow-sm hover:shadow-md transition-shadow duration-300 cursor-pointer"
              onClick={() => handleApprovalClick(approval)}
            >
              <div className="mr-4 flex-shrink-0">
                <div className="p-2 bg-blue-100 dark:bg-blue-800 rounded-full">
                  <FaProjectDiagram className="text-blue-500 dark:text-blue-400" />
                </div>
              </div>
              <div className="flex-1">
                <div className="flex justify-between items-center mb-1">
                  <h4 className="text-lg font-semibold text-navy-700 dark:text-white">
                    {approval.title}
                  </h4>
                  <div className="flex items-center text-gray-500 dark:text-gray-400 text-xs italic">
                    <FaRegClock className="mr-1" />
                    {new Date(approval.createdAt).toLocaleString()}
                  </div>
                </div>
                <p className="text-sm text-gray-600 dark:text-gray-300">
                  {approval.message}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p className="text-sm text-gray-600 dark:text-gray-300">No pending approvals</p>
        )}
      </div>
    </Card>
  );
};

export default ChartSection;
