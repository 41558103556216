import { useState, useEffect, useCallback } from "react";
import InputField from "components/fields/InputField";
import { FiSearch, FiUserPlus, FiX } from "react-icons/fi";
import { RiUserHeartFill } from "react-icons/ri";
import { searchUsersBySchool, getUserProfile } from "api";
import debounce from 'lodash.debounce';

const roles = ["Design Specialist", "Technical Specialist", "Research & Development Specialist", "Documentation & Presentation Specialist"];

const AddMembers = ({ setLocalMembers, teamData }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [noUserFound, setNoUserFound] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await getUserProfile();
        setCurrentUser(response.user);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      } finally {
        setLoadingProfile(false);
      }
    };

    fetchUserProfile();
  }, []);

  const handleSearchChange = useCallback(
    debounce(async (value) => {
      if (!value.trim() || loadingProfile) {
        setSearchResults([]);
        setNoUserFound(false);
        return;
      }

      if (!currentUser) return;

      try {
        setSearchLoading(true);
        const users = await searchUsersBySchool(teamData.schoolName, value);
        const filteredUsers = users.filter(user => user.username !== currentUser.username);

        if (filteredUsers.length > 0) {
          setSearchResults(filteredUsers);
          setNoUserFound(false);
        } else {
          setSearchResults([]);
          setNoUserFound(true);
        }
      } catch (error) {
        console.error("Error searching user:", error);
        setSearchResults([]);
        setNoUserFound(true);
      } finally {
        setSearchLoading(false);
      }
    }, 300),
    [currentUser, loadingProfile, teamData.schoolName]
  );

  const handleAddMember = useCallback((username) => {
    const memberToAdd = searchResults.find(user => user.username === username);

    if (memberToAdd) {
      const alreadyInTeam = selectedMembers.some(member => member.username === username);
      if (alreadyInTeam) {
        alert("This member is already in the team.");
        return;
      }

      const newMember = { ...memberToAdd, role: roles[0] }; // Default role
      setSelectedMembers(prevMembers => [...prevMembers, newMember]);
      setSearchQuery("");
      setSearchResults([]);
      setNoUserFound(false);
    }
  }, [searchResults, selectedMembers]);

  const handleRemoveMember = useCallback((username) => {
    setSelectedMembers(prevMembers =>
      prevMembers.filter(member => member.username !== username)
    );
  }, []);

  const handleRoleChange = useCallback((username, newRole) => {
    setSelectedMembers(prevMembers =>
      prevMembers.map(member =>
        member.username === username ? { ...member, role: newRole } : member
      )
    );
  }, []);

  useEffect(() => {
    setLocalMembers(selectedMembers); // Pass the selected members to the parent component
  }, [selectedMembers, setLocalMembers]);

  return (
    <div className="h-full w-full p-4 md:p-8 bg-white dark:bg-navy-700 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300">
      <h4 className="flex items-center gap-2 mb-6 text-xl md:text-2xl font-bold text-navy-700 dark:text-white">
        <FiUserPlus className="text-2xl md:text-3xl" /> Add Members
      </h4>
      <InputField
        extra="w-full mb-6 transition-shadow duration-300 ease-in-out hover:shadow-lg"
        label="Search Member by Username"
        placeholder="e.g., johndoe"
        id="searchmember"
        type="text"
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
          handleSearchChange(e.target.value);
        }}
        icon={<FiSearch className="text-xl" />}
      />
      {searchLoading && <div className="text-gray-500 dark:text-gray-400">Searching...</div>}
      {searchQuery && searchResults.length > 0 && searchResults.map((user) => (
        <div key={user.username} className="mb-4 p-2 bg-gray-100 dark:bg-navy-800 rounded-md shadow-inner transition-all duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-navy-600">
          <p
            className="cursor-pointer text-blue-500 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-600 transition-colors duration-200"
            onClick={() => handleAddMember(user.username)}
          >
            <RiUserHeartFill className="inline mr-2" /> {user.username}
          </p>
        </div>
      ))}
      {searchQuery && noUserFound && (
        <div className="mb-4 p-2 bg-gray-100 dark:bg-navy-800 rounded-md shadow-inner transition-all duration-300 ease-in-out hover:bg-gray-200 dark:hover:bg-navy-600">
          <p className="text-red-500 dark:text-red-400">User not found</p>
        </div>
      )}
      <div className="space-y-2">
        <h5 className="mb-2 text-lg font-bold text-navy-700 dark:text-white">
          Selected Members:
        </h5>
        <ul className="list-disc pl-5 space-y-2">
          {selectedMembers.map((member, index) => (
            <li
              key={index}
              className="flex items-center justify-between text-navy-700 dark:text-white"
            >
              <span className="flex items-center">
                <RiUserHeartFill className="inline mr-2" /> {member.username}
              </span>
              <div className="flex items-center space-x-4">
                <select
                  value={member.role}
                  onChange={(e) => handleRoleChange(member.username, e.target.value)}
                  className="bg-white dark:bg-navy-800 border border-gray-300 dark:border-navy-600 rounded-lg text-navy-700 dark:text-white px-3 py-2 text-sm"
                  aria-label={`Select role for ${member.username}`}
                >
                  {roles.map((role, idx) => (
                    <option key={idx} value={role}>{role}</option>
                  ))}
                </select>
                <FiX
                  className="text-red-500 cursor-pointer dark:text-red-400"
                  onClick={() => handleRemoveMember(member.username)}
                  aria-label={`Remove ${member.username}`}
                />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default AddMembers;
