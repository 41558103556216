import { useState, useEffect } from "react";
import Card from "components/card";

const Profile = ({profile, onImageChange}) => {
  const [avatar, setAvatar] = useState(profile.imageUrl);


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      onImageChange(file); // Call the handler passed from the parent
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatar(reader.result); // Update the local avatar state for instant feedback
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    setAvatar(profile.imageUrl); // Update avatar if profile image changes
  }, [profile.imageUrl]);

  return (
    <Card extra={"w-full py-[18px] px-[18px] h-[160px]"}>
      <div className="flex items-center gap-3">
        <div className="relative flex h-[90px] w-[90px] items-center justify-center rounded-full">
          <img
            className="h-full w-full rounded-full"
            src={avatar}
            alt="profile"
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="absolute inset-0 opacity-0 cursor-pointer"
            title="Change profile picture"
          />
        </div>
        <div>
          <p className="text-xl font-bold text-navy-700 dark:text-white">
            {`${profile.firstName} ${profile.lastName}`}
          </p>
          <p className="text-base text-gray-600">{profile.email}</p>
        </div>
      </div>
      <p className="text-sm text-gray-500 mt-2">Click on the picture to change it.</p>
    </Card>
  );
};

export default Profile;
