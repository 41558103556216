import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import SingleChat from "./components/SingleChat";
import CardMenu from "components/card/CardMenu";
import { BsPencilSquare } from "react-icons/bs";
import { FiSearch } from "react-icons/fi";
import Chat from "./components/Chat";
import Card from "components/card";
import { fetchMessages, fetchTeamMembers, getUserProfile, markMessagesAsRead, fetchMentorBySchool, fetchUserById } from "api";
import { fetchUsersBySchool } from "api";

const Messages = () => {
  const [open, setOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [allMessages, setAllMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [creatingNewChat, setCreatingNewChat] = useState(false);
  const searchInputRef = useRef(null);
  const [userProfile, setUserProfile] = useState(null);
  const [mentor, setMentor] = useState(null);

  const location = useLocation();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const profile = await getUserProfile();
        if (profile && profile.user) {
          setUserProfile(profile.user);
          console.log("User Profile:", profile.user);

          // Fetch the mentor for the user's school if the school exists
          if (profile.user.school) {
            try {
              console.log("Fetching mentor for school:", profile.user.school);
              const mentorId = await fetchMentorBySchool(profile.user.school);
              console.log("Fetched Mentor ID:", mentorId);

              if (mentorId) {
                // Fetch full mentor details using the mentorId
                const mentorDetails = await fetchUserById(mentorId);
                if (mentorDetails && mentorDetails.user) {
                  setMentor(mentorDetails.user);
                  console.log("Fetched Mentor Details:", mentorDetails.user);
                } else {
                  console.warn("Mentor details not found.");
                }
              } else {
                console.warn("Mentor ID not found.");
              }
            } catch (error) {
              console.error("Error fetching mentor:", error);
            }
          }
        }
      } catch (error) {
        console.error("Error fetching the user profile", error);
      }
    };
    fetchProfile();
  }, []);

  useEffect(() => {
    if (userProfile) {
      const fetchUsers = async () => {
        try {
          if (userProfile.role === 'mentor') {
            // Fetch all school users if the user is a mentor
            console.log("Fetching all school members for school:", userProfile.school);
            const schoolUsers = await fetchUsersBySchool(userProfile.school); // Ensure this function fetches all users by school
            setAllUsers(schoolUsers || []);
            console.log("Fetched School Users:", schoolUsers);
          } else {
            // Fetch only team members for regular users
            console.log("Fetching team members for team ID:", userProfile.team?.id);
            const groupMembers = await fetchTeamMembers(userProfile.team?.id);
            setAllUsers(groupMembers || []);
            console.log("Fetched Team Members:", groupMembers);
          }
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      };
      fetchUsers();
    }
  }, [userProfile]);

  useEffect(() => {
    if (userProfile) {
      const getMessages = async () => {
        try {
          console.log("Fetching messages for user ID:", userProfile.id);
          const messagesData = await fetchMessages(userProfile.id);
          console.log("Fetched Messages:", messagesData);

          // Filter messages based on role
          const relevantMessages = userProfile.role === 'mentor'
            ? messagesData // For mentors, show messages from all school members
            : messagesData.filter(message =>
                allUsers.some(user => user.id === message.sender.id || user.id === message.receiver.id)
              ); // For regular users, show messages from team members and the mentor

          setAllMessages(relevantMessages);

          const uniqueUsers = relevantMessages
            .flatMap((message) => [message.sender, message.receiver])
            .filter(
              (user, index, self) =>
                user &&
                index === self.findIndex((u) => u.id === user.id) &&
                user.id !== userProfile.id
            );
          setUsers(uniqueUsers);
          console.log("Unique Users in Messages:", uniqueUsers);
        } catch (error) {
          console.error("Error fetching messages:", error);
        }
      };
      getMessages();
    }
  }, [userProfile, allUsers]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const chatWithId = queryParams.get("chatWith");

    if (chatWithId && allUsers.length > 0) {
      const chatUser = allUsers.find(user => user.id === parseInt(chatWithId));
      if (chatUser) {
        setSelectedUser(chatUser);
        setOpen(true);
        console.log("Selected Chat User:", chatUser);
      }
    }
  }, [location.search, allUsers]);

  useEffect(() => {
    if (selectedUser && selectedUser.id) {
      const userMessages = allMessages.filter(
        (message) =>
          (message.sender.id === selectedUser.id && message.receiver.id === userProfile.id) ||
          (message.receiver.id === selectedUser.id && message.sender.id === userProfile.id)
      );
      setMessages(userMessages.reverse());

      const unreadMessages = userMessages.filter(
        (message) => message.receiver.id === userProfile.id && !message.isRead
      );
      if (unreadMessages.length > 0) {
        markMessagesAsRead(unreadMessages.map((msg) => msg.id));
      }
      console.log("Messages with Selected User:", userMessages);
    } else {
      setMessages([]);
    }
  }, [selectedUser, allMessages]);

  useEffect(() => {
    if (open && window.innerWidth < 800) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "unset";
    }
  }, [open]);

  const getLastMessage = (userId) => {
    const lastMessage = allMessages
      .filter(
        (message) =>
          (message.sender.id === userId && message.receiver.id === userProfile.id) ||
          (message.receiver.id === userId && message.sender.id === userProfile.id)
      )
      .pop();

    return lastMessage ? lastMessage.content : "No messages yet";
  };

  if (!userProfile) {
    return <div>Loading...</div>;
  }

  // Filter users by schoolId and exclude the current user
  const filteredUsers = allUsers.filter(user => user.schoolId === userProfile.schoolId && user.id !== userProfile.id);

  // Include mentor in the filtered users if they exist and are not already in the list
  if (mentor && !filteredUsers.some(user => user.id === mentor.id)) {
    filteredUsers.push(mentor);
    console.log("Mentor added to filtered users:", mentor);
  }

  console.log("Filtered Users:", filteredUsers);

  const handleCreateNewChat = () => {
    setSelectedUser(null);
    setMessages([]);
    setCreatingNewChat(true);
    setOpen(true);
    searchInputRef.current.focus();
  };

  return (
    <div className="mt-[80px] flex w-full grid-cols-10 flex-col gap-5 md:mt-[20px] lg:mt-6 lg:min-h-[85vh] lg:flex-row">
      <div className="w-full lg:w-[35%]">
        <Card extra={"w-full h-full p-4"}>
          {/* Header */}
          <div className="mb-3 flex items-center justify-between">
            <h1 className="font-dm text-[24px] font-bold text-navy-700 dark:text-white">
              Your Chats
            </h1>
            <CardMenu vertical />
          </div>
          {/* Search */}
          <div className="flex w-full items-center gap-2">
            <div className="flex h-[42px] w-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white">
              <p className="pl-3 pr-2 text-xl">
                <FiSearch className="h-4 w-4 text-gray-400 dark:text-gray-100" />
              </p>
              <input
                type="text"
                placeholder="Search..."
                className="block h-full w-full rounded-full bg-lightPrimary text-sm font-medium text-navy-700 outline-none placeholder:text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:text-gray-100 sm:w-fit"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                ref={searchInputRef}
              />
            </div>
            <button
              className="flex items-center justify-center rounded-full bg-brand-500 p-[12px] text-base font-medium text-white transition duration-200 hover:bg-blue-700 active:bg-blue-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:opacity-90"
              onClick={handleCreateNewChat}
            >
              <BsPencilSquare />
            </button>
          </div>
          {/* Messages */}
          <div className="mt-4">
            {filteredUsers.length === 0 ? (
              <div className="text-center text-gray-500 dark:text-gray-300">
                No users found.
              </div>
            ) : (
              filteredUsers.map((user, index) => (
                <div
                  key={index}
                  onClick={() => {
                    setOpen(true);
                    setSelectedUser(user);
                    setCreatingNewChat(false);
                    console.log("Selected User for Chat:", user);
                  }}
                >
                  <Chat
                    photo={user.imageUrl || 'https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y'}
                    active={user.status === 'Online'}
                    name={`${user.firstName || ''} ${user.lastName || 'Unknown User'}`.trim()}
                    time="Last seen"
                    message={getLastMessage(user.id)} // Display last message preview
                  />
                </div>
              ))
            )}
          </div>
        </Card>
      </div>
      <div className="w-full lg:w-[64.5%]">
        {selectedUser && (
          <SingleChat
            open={open}
            onClose={() => setOpen(false)}
            user={selectedUser}
            messages={messages}
            setMessages={setMessages}
            creatingNewChat={creatingNewChat}
            userId={userProfile.id}
            userProfile={userProfile}
            selectedUser={selectedUser}
          />
        )}
      </div>
    </div>
  );
};

export default Messages;
