import React, { useEffect, useState } from 'react';
import Card from 'components/card';
import { fetchUnreadMessages, getUserProfile } from 'api'; // Add fetchUnreadMessages and getUserProfile
import { FiMail } from 'react-icons/fi';

const InboxCard = () => {
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const loadMessages = async () => {
      try {
        setLoading(true);
  
        // Get the logged-in user's profile to obtain the userId
        const profile = await getUserProfile();
        const userId = profile.user.id;
  
        // Fetch unread messages for the user
        const unreadMessages = await fetchUnreadMessages(userId);
        setMessages(unreadMessages);
      } catch (error) {
        setError('Failed to load messages');
      } finally {
        setLoading(false);
      }
    };
  
    loadMessages();
  }, []);

  return (
    <Card extra="p-6 rounded-[20px] bg-white dark:bg-navy-800 shadow-lg h-full">
      <h3 className="text-xl font-bold text-navy-700 dark:text-white mb-4 flex items-center">
        <FiMail className="mr-2" />
        Inbox
      </h3>
      {loading ? (
        <p className="text-gray-600 dark:text-gray-300">Loading messages...</p>
      ) : error ? (
        <p className="text-red-600 dark:text-red-400">{error}</p>
      ) : messages.length === 0 ? (
        <p className="text-gray-600 dark:text-gray-300">No unread messages.</p>
      ) : (
        <ul className="space-y-4 overflow-y-auto" style={{ maxHeight: '24rem' }}>
          {messages.map((message) => (
            <li
              key={message.id}
              className="flex items-center space-x-4 p-4 dark:bg-navy-700 rounded-lg hover:bg-gray-100 dark:hover:bg-navy-600 transition duration-300 ease-in-out"
            >
              <img
                src={message.sender.imageUrl || 'https://via.placeholder.com/48'} // Fallback image
                alt={message.sender.firstName}
                className="h-12 w-12 rounded-full object-cover border-2 border-white dark:border-navy-800 shadow-sm"
              />
              <div className="flex-1">
                <div className="flex justify-between items-center mb-1">
                  <h4 className="text-base font-semibold text-navy-700 dark:text-white">
                    {message.sender.firstName} {message.sender.lastName}
                  </h4>
                  <span className="text-xs text-gray-500 dark:text-gray-400">{message.timestamp}</span>
                </div>
                <p className="text-sm text-gray-600 dark:text-gray-300 leading-relaxed">
                  {message.content}
                </p>
              </div>
            </li>
          ))}
        </ul>
      )}
    </Card>
  );
};

export default InboxCard;
