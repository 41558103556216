import React, { useState, useEffect } from "react";
import {
  MdFlag,
  MdCheck,
  MdChevronRight,
  MdLock,
  MdExpandLess,
  MdExpandMore,
  MdPlayArrow,
  MdHourglassEmpty,
} from "react-icons/md";
import Card from "components/card";
import CircularProgressMini from "components/charts/CircularProgressMini";
import LaunchPoap from "assets/img/profile/LaunchPoap.png";
import ErrorAlerts from "components/alerts/ErrorAlerts";
import SuccessAlerts from "components/alerts/SuccessAlerts"; // Add SuccessAlert component
import { fetchTeamStepStatus, createNotification, fetchMentorBySchool } from "api";
import { getUserProfile } from "api";

const Completed = ({ poap, poapIndex, teamId, school, userId }) => {
  const { id: descriptionId, title, learnings = '', totalPoints = 0, categories = [] } = poap || {};

  const [expandedTopics, setExpandedTopics] = useState({});
  const [expandedSteps, setExpandedSteps] = useState({});
  const [teamSteps, setTeamSteps] = useState({});
  const [loadingSteps, setLoadingSteps] = useState({});
  const [mentorId, setMentorId] = useState(null);
  const [userProfile, setUserProfile] = useState(null); // State for user profile
  const [errorMessages, setErrorMessages] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null); // Success message state
  const [waitingApprovalSteps, setWaitingApprovalSteps] = useState({}); // State for steps waiting for approval

  const validCategories = categories.map(category => ({
    name: category.name || "Unnamed Category",
    steps: category.steps ? category.steps.sort((a, b) => a.id - b.id) : []
  }));

  useEffect(() => {
    const fetchSteps = async () => {
      try {
        if (teamId && descriptionId) {
          const fetchedSteps = await fetchTeamStepStatus(teamId, descriptionId);
          const stepsMap = fetchedSteps.reduce((acc, step) => {
            acc[step.poapStepId] = step.status;
            return acc;
          }, {});
          setTeamSteps(stepsMap);
        }
      } catch (error) {
        console.error("Error fetching steps:", error);
      }
    };
    
    fetchSteps();
  }, [teamId, descriptionId]);

  useEffect(() => {
    const fetchMentor = async () => {
      try {
        if (school) {
          const fetchedMentorId = await fetchMentorBySchool(school);
          if (fetchedMentorId) {
            setMentorId(fetchedMentorId);
          } else {
            console.log("Mentor ID not found");
          }
        }
      } catch (error) {
        console.error("Error fetching mentor ID:", error);
      }
    };
    
    fetchMentor();
  }, [school]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profile = await getUserProfile();
        setUserProfile(profile.user);
      } catch (error) {
        console.error("Error fetching user profile:", error);
      }
    };

    fetchUserProfile();
  }, []);

  const toggleTopic = (catIndex) => {
    setExpandedTopics(prevState => ({
      ...prevState,
      [catIndex]: !prevState[catIndex],
    }));
  };

  const toggleStep = (catIndex, stepIndex) => {
    const key = `${catIndex}-${stepIndex}`;
    setExpandedSteps(prevState => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };

  const areAllStepsCompleted = (steps) => {
    return steps.every(step => teamSteps[step.id] === "completed");
  };

  const isStepUnlocked = (categorySteps, stepIndex) => {
    if (stepIndex === 0) {
      return true;
    }
    const previousStep = categorySteps[stepIndex - 1];
    const previousStepStatus = teamSteps[previousStep.id];
    return previousStepStatus === 'completed';
  };

  const getStepStatusIcon = (status, unlocked, isCurrentStep, stepId) => {
    if (!unlocked) return <MdLock />;
    if (status === "completed") return <MdCheck />;
    if (waitingApprovalSteps[stepId]) return <MdHourglassEmpty className="animate-spin" />; // Show hourglass if waiting for approval
    if (isCurrentStep) return <MdPlayArrow />;
    return <MdLock />;
  };

  const calculateStepProgress = (status, unlocked, isCurrentStep) => {
    if (!unlocked) return 0;
    if (status === "completed") return 100;
    return 0;
  };

  const isCurrentPoapCompleted = validCategories.every(category =>
    areAllStepsCompleted(category.steps)
  );

  useEffect(() => {
    if (poap) {
      poap.isCompleted = isCurrentPoapCompleted;
    }
  }, [isCurrentPoapCompleted, poap]);

  const handleRequestApproval = async (stepId, stepName) => {
    setLoadingSteps(prevState => ({
      ...prevState,
      [stepId]: true,
    }));
    setErrorMessages([]);
    setSuccessMessage(null);
  
    try {
      let currentMentorId = mentorId;
  
      if (!mentorId) {
        const fetchedMentorId = await fetchMentorBySchool(school);
        if (fetchedMentorId) {
          setMentorId(fetchedMentorId);
          currentMentorId = fetchedMentorId;  // Use the fetched mentor ID
        } else {
          throw new Error("Mentor ID not found");
        }
      }

      const currentUserName = `${userProfile.firstName} ${userProfile.lastName}`; // Fetch current user's name
      const teamName = userProfile.team?.name || "Unknown Team"; // Extract team name from user profile
  
      await createNotification({
        userId: currentMentorId,  // Use the currentMentorId here
        title: "Approval Request",
        message: `${currentUserName} from team ${teamName} has requested approval for the task: ${stepName}.`,
        type: "approval_request",
        priority: "high",
        relatedId: stepId,
      });
  
      setSuccessMessage("Approval request sent successfully.");
      setWaitingApprovalSteps(prevState => ({
        ...prevState,
        [stepId]: true, // Set the step to waiting for approval
      }));
    } catch (error) {
      console.error("Error sending approval request:", error);
      setErrorMessages(["No mentor assigned to this school yet, please contact support."]);
    } finally {
      setLoadingSteps(prevState => ({
        ...prevState,
        [stepId]: false,
      }));
    }
  };

  const handleCloseError = () => {
    setErrorMessages([]);
  };

  const handleCloseSuccess = () => {
    setSuccessMessage(null);
  };

  return (
    <div>
      {errorMessages.length > 0 && <ErrorAlerts errors={errorMessages} onClose={handleCloseError} />}
      {successMessage && <SuccessAlerts message={successMessage} onClose={handleCloseSuccess} />} {/* Add SuccessAlert */}
      <Card extra={"w-full p-4"}>
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">{title}</h4>
        <div className="mt-3 flex items-center justify-between">
          <p className="text-base font-medium uppercase text-gray-600">
            {validCategories.length} {validCategories.length > 1 ? "Topics" : "Topic"}
          </p>
          <p className={`text-lg ${isCurrentPoapCompleted ? "text-green-500" : "text-orange-500"}`}>
            <MdFlag />
          </p>
        </div>
        <div className="mt-[20px] grid w-full grid-cols-5 gap-2">
          {validCategories.map((category, index) => (
            <div
              key={index}
              className={`h-2 w-full rounded-[36px] ${areAllStepsCompleted(category.steps) ? "bg-green-500" : "bg-orange-500"}`}
            />
          ))}
        </div>

        <div>
          {validCategories.map((category, catIndex) => (
            <div key={catIndex}>
              <h5
                className="mt-4 text-lg font-bold text-navy-700 dark:text-white flex items-center justify-between cursor-pointer"
                onClick={() => toggleTopic(catIndex)}
              >
                {category.name}
                {category.steps.length > 1 && (
                  expandedTopics[catIndex] ? <MdExpandLess /> : <MdExpandMore />
                )}
              </h5>
              {(expandedTopics[catIndex] || category.steps.length === 1) && (
                <div>
                  {category.steps.map((step, stepIndex) => {
                    const unlocked = isStepUnlocked(category.steps, stepIndex);
                    const stepKey = `${catIndex}-${stepIndex}`;
                    const status = teamSteps[step.id] || 'not started';
                    const isCurrentStep = unlocked && status !== 'completed';

                    return (
                      <div key={stepIndex}>
                        <div
                          className="mt-[19px] flex items-center justify-between gap-2"
                          onClick={() => unlocked && toggleStep(catIndex, stepIndex)}
                        >
                          <div className="flex gap-2">
                            <div className="w-[30px]">
                              <CircularProgressMini
                                step={stepIndex + 1}
                                percentage={calculateStepProgress(status, unlocked, isCurrentStep)}
                                color={isCurrentStep ? "orange" : status === "completed" ? "green" : "gray"}
                              />
                            </div>
                            <div className="flex items-center gap-1 font-medium text-gray-600 dark:text-white">
                              <p>{step.step} <b>({step.points} points)</b></p>
                              <p className="mt-1 text-lg">
                                {getStepStatusIcon(status, unlocked, isCurrentStep, step.id)}
                              </p>
                            </div>
                          </div>
                          <p className="text-2xl text-gray-600 dark:text-white">
                            {expandedSteps[stepKey] && unlocked ? <MdExpandLess /> : <MdChevronRight />}
                          </p>
                        </div>
                        {expandedSteps[stepKey] && unlocked && (
                          <div className="ml-8 mt-2 text-sm text-gray-600 dark:text-gray-300">
                            {step.detail}
                            {status !== "completed" && isCurrentStep && (
                              <div className="mt-2">
                                <button
                                  className="text-sm text-brand-500 hover:underline focus:outline-none flex items-center gap-1"
                                  onClick={() => handleRequestApproval(step.id, step.step)}
                                  disabled={loadingSteps[step.id]} // Disable the button while loading
                                >
                                  {loadingSteps[step.id] || waitingApprovalSteps[step.id] ? (
                                    <>
                                      <MdHourglassEmpty className="animate-spin" />
                                      Waiting for Approval
                                    </>
                                  ) : (
                                    "Request Approval"
                                  )}
                                </button>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="mt-8">
          <h5 className="text-xl font-bold text-navy-700 dark:text-white">What you’ll learn</h5>
          <p className="mt-3 text-navy-700 dark:text-white">{learnings}</p>
        </div>

        <div className="mt-[52px]">
          <h5 className="mb-3 text-xl font-bold text-navy-700 dark:text-white">By the numbers</h5>
          <div className="flex w-max flex-wrap items-center justify-between gap-5 lg:w-full">
            <div>
              <div className="flex items-center gap-1">
                <h5 className="text-gray-600">Total Points:</h5>
                <p className="font-medium text-navy-700 dark:text-white">{totalPoints}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[52px]">
          <h5 className="text-xl font-bold text-navy-700 dark:text-white">Innovator's Badge</h5>
          <div className="mt-3 w-full rounded-[20px] bg-lightPrimary p-3 shadow-2xl shadow-lightPrimary dark:shadow-none">
            <img className="w-full rounded-[20px]" src={LaunchPoap} alt={title} />
          </div>
        </div>
      </Card>
    </div>
  );
};

export default Completed;
