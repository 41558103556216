import { useState } from "react";
import { MdGroup, MdEvent } from "react-icons/md";

const NavigationTabs = ({ onTabChange }) => {
  const [toggleState, setToggleInitialTab] = useState(1);

  const toggleTab = (index) => {
    setToggleInitialTab(index);
    onTabChange(index); // Update the parent component's state
  };

  return (
    <div className="flex h-full w-full flex-col items-center overflow-x-scroll px-4 pt-[2px] lg:overflow-x-hidden lg:px-10">
      <div className="mt-[80px] md:mt-[44px] flex w-full justify-start gap-24 overflow-hidden border-b-2 border-gray-200 text-3xl dark:!border-white/10 lg:gap-[50px]">
        {[
          { icon: <MdGroup />, label: "Team", id: 1 },
          { icon: <MdEvent />, label: "Calendar", id: 2 },
        ].map((tab) => (
          <div
            key={tab.id}
            className={`flex items-center gap-2 ${
              toggleState === tab.id
                ? "border-b-4 border-brand-500 pb-3 hover:cursor-pointer dark:border-brand-400"
                : "pb-[20px] hover:cursor-pointer"
            }`}
            onClick={() => toggleTab(tab.id)}
          >
            <p className="text-lg text-navy-700 dark:text-white">{tab.icon}</p>
            <p className="text-[18px] font-medium text-navy-700 dark:text-white">{tab.label}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NavigationTabs;
