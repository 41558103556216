import React, { useState, useEffect } from "react";
import avatar from "assets/img/avatars/avatar11.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import { getUserProfile, uploadProfilePic } from "api";
import md5 from "md5";

const getGravatarUrl = (email) => {
  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
};

const MentorBanner = () => {
  const [user, setUser] = useState({
    name: '',
    role: 'iForge Mentor',
    imageUrl: '',
  });

  const [hovered, setHovered] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userProfile = await getUserProfile();
        const email = userProfile?.user?.email || '';
        const imageUrl = userProfile?.user?.imageUrl || getGravatarUrl(email);

        
        setUser(prevUser => ({
          ...prevUser,
          name: `${userProfile.user.firstName} ${userProfile.user.lastName}`,
          imageUrl,
        }));
      } catch (error) {
        console.error("Error fetching user data:", error);
        // Use default avatar if fetch fails
        setUser(prevUser => ({
          ...prevUser,
          imageUrl: avatar,
        }));
      }
    };

    fetchUserData();
  }, []);

  const handleFileChange = (event) => setSelectedFile(event.target.files[0]);

  const handleFileUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('profilePic', selectedFile);

    try {
      const response = await uploadProfilePic(formData);
      setUser(prevUser => ({
        ...prevUser,
        imageUrl: response.imageUrl,
      }));
      setSelectedFile(null);
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      handleFileUpload();
    }
  }, [selectedFile]);


  return (
    <Card extra="items-center w-full h-full p-4 bg-cover">
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div
          className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <img className="h-full w-full rounded-full" src={user.imageUrl} alt="Profile picture" />
          {hovered && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full">
              <label className="cursor-pointer text-white">
                <span>Change</span>
                <input
                  type="file"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </label>
            </div>
          )}
        </div>
      </div>

      <div className="mt-16 flex flex-col items-center text-center">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white md:text-xl">
          {user.name}
        </h4>
        <h5 className="text-sm font-normal text-gray-600 dark:text-gray-300 md:text-base">{user.role}</h5>
      </div>
    </Card>
  );
};

export default MentorBanner
