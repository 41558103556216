import Default from "layouts/auth/types/Default";
import React, { useState } from "react";
import PinInput from "react-pin-input";
import { handleVerification, resendVerificationCode } from "api";
import { useNavigate } from "react-router-dom";

function VerificationDefault() {
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [email, setEmail] = useState("");
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [resendAttempts, setResendAttempts] = useState(0);
  const navigate = useNavigate();

  const handleVerificationClick = async () => {
    try {
      const response = await handleVerification(code);
      if (response.msg === "Email verified successfully") {
        navigate("/auth/sign-in");
      } else {
        setError("Verification failed");
      }
    } catch (err) {
      setError(err.response?.data?.msg || "Verification failed");
    }
  };

  const handleResendCode = async () => {
    if (resendAttempts >= 3) {
      setError("Maximum resend attempts reached");
      return;
    }

    if (!email || !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    try {
      const response = await resendVerificationCode({ email });
      if (response.msg === "Verification code sent") {
        setSuccess("Verification code resent successfully");
        setResendAttempts(resendAttempts + 1);
        setShowEmailInput(false);
      } else {
        setError("Failed to resend the verification code");
      }
    } catch (err) {
      setError(err.response?.data?.msg || "Failed to resend verification code");
    }
  };

  return (
    <Default
      maincard={
        <div className="relative mb-16 flex h-full w-full items-center justify-center dark:!bg-navy-900 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          {/* Sign in section */}
          <div className="relative mt-[16vh] flex flex-col rounded-[20px] bg-white pb-16 pt-12 dark:!bg-navy-900 md:pr-6 lg:pr-0 xl:pr-24 transition-all duration-500 ease-in-out">
            <h2 className="mt-1 text-4xl font-bold text-navy-700 dark:text-white">
              2-Step Verification
            </h2>
            <p className="mt-[15px] ml-1 text-[15px] text-gray-600">
              {showEmailInput
                ? "Please enter your email to receive a new verification code."
                : "Enter your 2-Step Verification email code to unlock!"}
            </p>
            {error && <p className="text-red-500 mt-4">{error}</p>}
            {success && <p className="text-green-500 mt-4">{success}</p>}
            {/* verification */}
            <div className="mt-7 mb-4 flex w-full items-center justify-center">
              {showEmailInput ? (
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="border-2 border-gray-300 rounded-2xl w-[368px] h-[92px] text-center text-xl dark:bg-navy-800 dark:border-navy-700 dark:text-white focus:outline-none focus:border-orange-500 focus:ring-2 focus:ring-orange-500 transition-opacity duration-500 ease-in-out"
                  aria-label="Email input"
                />
              ) : (
                <PinInput
                  length={4}
                  initialValue=""
                  onChange={(value) => setCode(value)}
                  type="numeric"
                  inputMode="number"
                  style={{ marginBottom: "10px" }}
                  inputStyle={{
                    borderWidth: "2px",
                    width: "92px",
                    height: "92px",
                    borderColor: "#E0E5F2",
                    borderRadius: "16px",
                    color: "#2B3674",
                    fontSize: "36px",
                  }}
                  inputFocusStyle={{ borderColor: "#FF5722", borderWidth: "3px" }}
                  onComplete={(value) => setCode(value)}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  className="transition-opacity duration-500 ease-in-out"
                  aria-label="Verification code input"
                />
              )}
            </div>
            {/* button */}
            <button
              className="h-[50px] w-full rounded-xl bg-orange-500 text-base font-medium text-white transition duration-200 hover:bg-orange-600 dark:bg-orange-400 dark:text-white dark:hover:bg-orange-300 dark:active:bg-orange-200"
              onClick={showEmailInput ? handleResendCode : handleVerificationClick}
            >
              {showEmailInput ? "Resend Code" : "Unlock"}
            </button>
            <p className="mt-4 text-sm font-medium text-navy-700 dark:text-gray-500">
              {showEmailInput ? (
                <>
                  Remembered the code?
                  <span
                    className="text-sm font-medium text-orange-500 dark:text-white cursor-pointer"
                    onClick={() => setShowEmailInput(false)}
                  >
                    {" "}
                    Enter the code
                  </span>
                </>
              ) : (
                <>
                  Haven't received it?
                  <span
                    className="text-sm font-medium text-orange-500 dark:text-white cursor-pointer"
                    onClick={() => setShowEmailInput(true)}
                  >
                    {" "}
                    Resend a new code
                  </span>
                </>
              )}
            </p>
          </div>
        </div>
      }
    />
  );
}

export default VerificationDefault;
