import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import Checkbox from "components/checkbox";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "contexts/authContext";
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://academy.innovatorsforge.com';

function SignInDefault() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSignIn = async (event) => {
    event.preventDefault(); // Prevent default form submission
    let formErrors = {};

    // Basic email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) formErrors.email = 'Please enter your email address.';
    else if (!emailRegex.test(email)) formErrors.email = 'Please enter a valid email address.';

    if (!password) formErrors.password = 'Please enter your password.';

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }

    try {
      setIsLoading(true); // Disable the button while processing
      const response = await axios.post(`${API_BASE_URL}/api/auth/login`, {
        email,
        password,
        rememberMe
      });

      if (response.status === 200) {
        login(response.data.token, rememberMe); // Use login from context

        // Navigate to the appropriate dashboard based on the user's role
        const userRole = response.data.user.role;
        if (userRole === 'mentor') {
          navigate('/admin/mentor-hub'); // Navigate to mentor hub
        } else {
          navigate('/admin/dashboard'); // Navigate to dashboard on successful login
        }
      } else {
        // Handle non-successful responses
        setErrors({ general: 'Login failed. Please check your credentials and try again.' });
      }
    } catch (error) {
      // Handle errors if the API call fails
      setErrors({ general: error.response?.data?.msg || 'A network error occurred. Please try again later.' });
    } finally {
      setIsLoading(false); // Enable the button after processing
    }
  };

  return (
    <Default
      maincard={
        <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
          {/* Sign in section */}
          <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <h3 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
              Sign In
            </h3>
            <p className="mb-9 ml-1 text-base text-gray-600">
              Enter your email and password to sign in!
            </p>
            {errors.general && <p className="text-red-500">{errors.general}</p>}

            {/* Sign In Form */}
            <form onSubmit={handleSignIn}>
              {/* Email */}
              <InputField
                variant="auth"
                extra="mb-3"
                label="Email*"
                placeholder="mail@simmmple.com"
                id="email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                state={errors.email ? 'error' : ''}
                aria-label="Email"
                autoFocus
                required
              />
              {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
              
              {/* Password */}
              <InputField
                variant="auth"
                extra="mb-3"
                label="Password*"
                placeholder="Min. 8 characters"
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                state={errors.password ? 'error' : ''}
                aria-label="Password"
                required
              />
              {errors.password && <p className="text-red-500 text-xs">{errors.password}</p>}

              {/* Checkbox */}
              <div className="mb-4 flex items-center justify-between px-2">
                <div className="mt-2 flex items-center">
                  <Checkbox
                    id="checkbox"
                    color="orange"
                    checked={rememberMe}
                    onChange={(e) => setRememberMe(e.target.checked)}
                    aria-label="Keep me logged in"
                  />
                  <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                    Keep me logged In
                  </p>
                </div>
                <a
                  className="text-sm font-medium text-orange-400 hover:text-brand-900 dark:text-white"
                  href="/auth/forgot-password"
                >
                  Forgot Password?
                </a>
              </div>

              {/* Sign In Button */}
              <button
                className="w-full rounded-xl bg-orange-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-900 active:bg-dark dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                type="submit"
                disabled={isLoading}
                aria-label="Sign In"
              >
                {isLoading ? 'Signing In...' : 'Sign In'}
              </button>
            </form>

            <div className="mt-4">
              <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                Not registered yet?
              </span>
              <a
                href="/auth/sign-up"
                className="ml-1 text-sm font-medium text-orange-400 hover:text-brand-900 dark:text-white"
              >
                Create an account
              </a>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default SignInDefault;
