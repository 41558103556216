import LineChart from "components/charts/LineChart";
import { lineChartDataSidebar } from "variables/charts";
import { lineChartOptionsSidebar } from "variables/charts";
import { BsArrowsAngleExpand } from "react-icons/bs";
import LaunchPoap from "../../../assets/img/profile/LaunchPoap.png";
import firstPOAP from "../../../assets/img/poaps/explorerPOAP.png";
import secondPOAP from "../../../assets/img/poaps/shapeShifterPOAP.png";
import thirdPOAP from "../../../assets/img/poaps/masterFinsherPOAP.png";
import fourthPOAP from "../../../assets/img/poaps/creatorPOAP.png";
import { useEffect, useState } from "react";
import { fetchCurrentDescription, getUserProfile, fetchPoapDescriptions, fetchTeamStepStatus } from "api";
import { MdLockOutline } from "react-icons/md";

const FreeCard = ({ mini, hovered }) => {  
  const [currentPoap, setCurrentPoap] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [isPoapComplete, setIsPoapComplete] = useState(false);
  const [isMentor, setIsMentor] = useState(false);

  const getPoapImage = (id) => {
    switch (id) {
      case 1:
        return firstPOAP;
      case 2:
        return secondPOAP;
      case 3:
        return thirdPOAP;
      case 4:
        return fourthPOAP;
      default:
        return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userProfile = await getUserProfile();
        const teamId = userProfile.user.team?.id;
        setTeamId(teamId);

        if (userProfile.user.role === 'mentor') {
          setIsMentor(true);
          setIsPoapComplete(true);  // If user is a mentor, treat the POAP as complete
        } else {
          const poapDescriptions = await fetchPoapDescriptions();
          const currentDescription = await fetchCurrentDescription(teamId);

          const currentDescriptionId = currentDescription.descriptionId;

          const currentPoap = poapDescriptions.find(poap => poap.id === currentDescriptionId);
          setCurrentPoap(currentPoap);

          const teamStepStatus = await fetchTeamStepStatus(teamId, currentDescriptionId);
          const isComplete = teamStepStatus.every(step => step.status === 'completed');
          setIsPoapComplete(isComplete);
        }
      } catch (error) {
        console.error("Error fetching data:", error)
      }
    };

    fetchData();
  }, []);

  return (
    <div
      className={`relative flex h-[300px] w-[240px] flex-col items-center rounded-[20px] bg-white/30 dark:bg-[#FFFFFF]/10 backdrop-blur-lg border border-gray-200 dark:border-gray-700  ${
        mini === false
          ? ""
          : mini === true && hovered === true
          ? ""
          : "xl:mx-3.5 xl:justify-center"
      }`}
    >
      <BsArrowsAngleExpand
        className={`my-[100px] mx-5 h-6 w-6 text-white ${
          mini === true && hovered === false ? "block" : "hidden"
        }`}
      />
      
      <div
        className={`mt-8 flex flex-col items-center ${
          mini === false
            ? "block"
            : mini === true && hovered === true
            ? "block"
            : "hidden"
        }`}
      >
        <h4 className="text-2xl font-bold text-black">{isMentor ? "Role" : "Current Level"}</h4>
        <div className="mt-3 flex items-center justify-center rounded-[20px] bg-[#C9FBD5] py-1 px-2">
          <p className="text-xs font-bold text-green-500">
            {isMentor ? "Mentor" : currentPoap ? currentPoap.title : "Launch Poap"}
          </p>
        </div> 
      </div> 

      {/* Sidebar Card */}
      <div
        className={`relative h-full w-full flex justify-center items-center px-3 pb-3 ${
          mini === false
            ? "block"
            : mini === true && hovered === true
            ? "block"
            : "block xl:hidden"
        }`}
      >
        <div className="relative">
          <img
            src={isMentor ? LaunchPoap : currentPoap ? getPoapImage(currentPoap.id) : LaunchPoap}
            alt="Current POAP"
            className={`w-32 h-32 rounded-full object-cover ${isPoapComplete || isMentor ? '' : 'blur-md'}`} // Remove blur for mentor or completed POAP
          />
          {!isPoapComplete && !isMentor && (
            <div className="absolute inset-0 flex items-center justify-center bg-black/30 backdrop-blur-lg rounded-full">
              <MdLockOutline size={40} className="text-white opacity-80" />
            </div>
          )}
        </div>
      </div>
      
    </div>
  );
};

export default FreeCard;
