import { useState } from "react";
import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import React from "react";
import Checkbox from "components/checkbox";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://academy.innovatorsforge.com';

function SignUpDefault() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    role: '',
    institution: ''
  });
  const [errors, setErrors] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevState => ({ ...prevState, [id]: value }));
  };

  const handleSignUp = async () => {
    let formErrors = {};
  
    const nameRegex = /^[A-Za-z\s-]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    const trimmedFormData = {
      firstName: formData.firstName.trim(),
      lastName: formData.lastName.trim(),
      username: formData.username.trim(),
      email: formData.email.trim(),
      password: formData.password,
      confirmPassword: formData.confirmPassword,
      role: formData.role.trim() === 'Student' ? 'user' : formData.role.trim(),
      school: formData.institution.trim()
    };
  
    if (!trimmedFormData.firstName) formErrors.firstName = 'First name is required';
    else if (!nameRegex.test(trimmedFormData.firstName)) formErrors.firstName = 'First name must only contain letters, spaces, or hyphens';
  
    if (!trimmedFormData.lastName) formErrors.lastName = 'Last name is required';
    else if (!nameRegex.test(trimmedFormData.lastName)) formErrors.lastName = 'Last name must only contain letters, spaces, or hyphens';
  
    if (!trimmedFormData.username) formErrors.username = 'Username is required';
  
    if (!trimmedFormData.email) formErrors.email = 'Email is required';
    else if (!emailRegex.test(trimmedFormData.email)) formErrors.email = 'Email must be a valid email address';
  
    if (!trimmedFormData.password) formErrors.password = 'Password is required';
    else if (trimmedFormData.password.length < 8) formErrors.password = 'Password must be at least 8 characters long';
    if (trimmedFormData.password !== trimmedFormData.confirmPassword) formErrors.confirmPassword = 'Passwords do not match';
  
    if (!trimmedFormData.role) formErrors.role = 'Role is required';
    if (!trimmedFormData.school) formErrors.school = 'Institution is required';

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return;
    }
  
    try {
      console.log('Form Data:', trimmedFormData);
      const response = await axios.post(`${API_BASE_URL}/api/auth/register`, trimmedFormData);
  
      if (response.status === 201) {
        navigate('/auth/verification');
      } else {
        setErrors({ general: 'Signup failed' });
      }
    } catch (error) {
      if (error.response?.data?.msg === "Email already in use") {
        setErrors({ email: "Email already in use" });
      } else if (error.response?.data?.msg === "Username already in use") {
        setErrors({ username: "Username already in use" });
      } else {
        setErrors({ general: error.response?.data?.msg || 'Network error' });
      }
    }
  };
  
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Default
      maincard={
        <div className="mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          <div className="mt-[3vh] w-full max-w-full flex-col md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <h3 className="text-4xl font-bold text-navy-700 dark:text-white">
              Sign Up
            </h3>
            <p className="mt-[10px] ml-1 text-base text-gray-600 dark:text-gray-300">
              Enter your email and password to sign up!
            </p>
            {errors.general && <p className="text-red-500">{errors.general}</p>}
            <div className="mt-4 mb-4 flex items-center gap-3">
              <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
              <div className="h-px w-full bg-gray-200 dark:bg-navy-700" />
            </div>
            <div className="mb-3 flex w-full items-center justify-center gap-4">
              <div className="w-1/2">
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="First Name*"
                  placeholder="John"
                  id="firstName"
                  type="text"
                  value={formData.firstName}
                  onChange={handleChange}
                  state={errors.firstName ? 'error' : ''}
                />
                {errors.firstName && <p className="text-red-500 text-xs">{errors.firstName}</p>}
              </div>

              <div className="w-1/2">
                <InputField
                  variant="auth"
                  extra="mb-3"
                  label="Last Name*"
                  placeholder="Doe"
                  id="lastName"
                  type="text"
                  value={formData.lastName}
                  onChange={handleChange}
                  state={errors.lastName ? 'error' : ''}
                />
                {errors.lastName && <p className="text-red-500 text-xs">{errors.lastName}</p>}
              </div>
            </div>
            <InputField
              variant="auth"
              extra="mb-3"
              label="Username*"
              placeholder="johndoe123"
              id="username"
              type="text"
              value={formData.username}
              onChange={handleChange}
              state={errors.username ? 'error' : ''}
            />
            {errors.username && <p className="text-red-500 text-xs">{errors.username}</p>}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Email*"
              placeholder="mail@simmmple.com"
              id="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              state={errors.email ? 'error' : ''}
            />
            {errors.email && <p className="text-red-500 text-xs">{errors.email}</p>}
            <div className="mb-3">
              <label htmlFor="role" className="text-sm font-medium text-navy-700 dark:text-gray-300">Role*</label>
              <select
                id="role"
                className={`w-full mt-2 px-3 py-2 border text-sm rounded-lg text-gray-700 bg-white dark:bg-navy-700 dark:text-white focus:outline-none focus:ring-2 ${
                  errors.role ? 'border-red-500 focus:ring-red-500' : 'focus:ring-brand-900'
                }`}
                value={formData.role}
                onChange={handleChange}
              >
                <option value="" className="text-gray-500">Select your role</option>
                <option value="Student" className="text-gray-700 dark:text-gray-300">Student</option>
                <option value="Mentor" className="text-gray-700 dark:text-gray-300">Mentor</option>
              </select>
              {errors.role && <p className="text-red-500 text-xs">{errors.role}</p>}
            </div>
            <div className="mb-3">
              <label htmlFor="institution" className="text-sm font-medium text-navy-700 dark:text-gray-300">Institution*</label>
              <select
                id="institution"
                className={`w-full mt-2 px-3 py-2 border text-sm rounded-lg text-gray-700 bg-white dark:bg-navy-700 dark:text-white focus:outline-none focus:ring-2 ${
                  errors.institution ? 'border-red-500 focus:ring-red-500' : 'focus:ring-brand-900'
                }`}
                value={formData.institution}
                onChange={handleChange}
              >
                <option value="" className="text-gray-500">Select your institution</option>
                <option value="iForge Academy" className="text-gray-700 dark:text-gray-300">iForge Academy</option>
                <option value="Little Innovators School" className="text-gray-700 dark:text-gray-300">Little Innovators School</option>
                <option value="Itqan Australian Academy" className="text-gray-700 dark:text-gray-300">Itqan Australian Academy</option>
                <option value="Benadir University" className="text-gray-700 dark:text-gray-300">Benadir University</option>
                
              </select>
              {errors.institution && <p className="text-red-500 text-xs">{errors.institution}</p>}
            </div>
            <InputField
              variant="auth"
              extra="mb-3"
              label="Password*"
              placeholder="Min 8 characters"
              id="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              state={errors.password ? 'error' : ''}
            />
            {errors.password && <p className="text-red-500 text-xs">{errors.password}</p>}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Confirm Password*"
              placeholder="Min 8 characters"
              id="confirmPassword"
              type="password"
              value={formData.confirmPassword}
              onChange={handleChange}
              state={errors.confirmPassword ? 'error' : ''}
            />
            {errors.confirmPassword && <p className="text-red-500 text-xs">{errors.confirmPassword}</p>}
            <div className="mt-4 flex items-center justify-between px-2">
              <div className="flex">
                <Checkbox id="checkbox" color="orange" onChange={handleCheckboxChange} checked={isChecked}/>
                <label
                  htmlFor="checkbox"
                  className="ml-2 text-sm text-navy-700 hover:cursor-pointer dark:text-white"
                >
                  By creating an account means you agree to the Terms and
                  Conditions, and our Privacy Policy
                </label>
              </div>
            </div>
            <button
              className={`mt-4 w-full rounded-xl py-3 text-base font-medium text-white transition duration-200 ${
                isChecked ? 'bg-orange-500 hover:bg-brand-900 active:bg-dark dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200' : 'bg-gray-500 cursor-not-allowed'
              }`}
              onClick={handleSignUp}
              disabled={!isChecked}
            >
              Create my account
            </button>
            <div className="mt-3">
              <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
                Already a member?
              </span>
              <a
                href="/auth/sign-in/"
                className="ml-1 text-sm font-medium text-orange-400 hover:text-brand-900 dark:text-white"
              >
                Sign In
              </a>
            </div>
          </div>
        </div>
      }
    />
  );
}

export default SignUpDefault;
