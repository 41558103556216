import React from 'react';
import Card from 'components/card';
import { MdGroup, MdAutorenew, MdAccessTime, MdDoneAll, MdOutlineMenuBook } from "react-icons/md";

const iconMap = {
  MdGroup: MdGroup,
  MdAutorenew: MdAutorenew,
  MdAccessTime: MdAccessTime,
  MdDoneAll: MdDoneAll,
  MdOutlineMenuBook: MdOutlineMenuBook,
};

const StatsCard = ({ title, value, icon }) => {
  const IconComponent = iconMap[icon];

  return (
    <Card 
      extra="p-8 bg-white bg-opacity-30 backdrop-filter backdrop-blur-lg rounded-[20px] shadow-lg border border-gray-200 dark:border-gray-700"
      style={{ borderColor: 'rgba(255, 255, 255, 0.2)', boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)' }}
    >
      <div className="flex items-center">
        <div className="inline-flex flex-shrink-0 items-center justify-center h-16 w-16 bg-gray-200 dark:bg-navy-700 rounded-full mr-6">
          <IconComponent className="h-6 w-6 text-orange-500" />
        </div>
        <div>
          <span className="block text-2xl font-bold text-navy-700 dark:text-white">{value}</span>
          <span className="block text-gray-600 dark:text-gray-300">{title}</span>
        </div>
      </div>
    </Card>
  );
};

export default StatsCard;
