import React, { createContext, useContext, useState, useEffect } from 'react';
import { isTokenExpired, saveToken, removeToken, getToken } from 'utils/auth';
import {jwtDecode} from 'jwt-decode';
import io from 'socket.io-client';

const AuthContext = createContext();
let socket; // Singleton instance

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null); // New state for user info including role
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = getToken();
    if (token && !isTokenExpired(token)) {
      const decodedToken = jwtDecode(token);
      setIsAuthenticated(true);
      setUser(decodedToken); // Set the user state with decoded token info

      console.log("User permissions after setting:", decodedToken.permissions);

      // Only initialize socket if it doesn't exist
      if (!socket) {
        socket = io('https://academy.innovatorsforge.com'); 

        socket.on('connect', () => {
          console.log('Socket connected:', socket.id);

          // Join the user's room
          socket.emit('joinRoom', decodedToken.id);
          console.log(`Emitting joinRoom for user ${decodedToken.id}`);
        });

        socket.on('disconnect', () => {
          console.log('Socket disconnected:', socket.id);
        });
      }

      // Handle auto-logout if token expires
      if (!localStorage.getItem('rememberMe')) {
        setTimeout(() => {
          logout();
        }, decodedToken.exp * 1000 - Date.now());
      }
    } else {
      setLoading(false); // Ensure loading is set to false if not authenticated
    }

    return () => {
      // Cleanup on component unmount
      if (socket) {
        socket.disconnect();
        console.log('Socket disconnected during cleanup');
        socket = null; // Clear socket state
      }
    };
  }, []); // Run only on mount

  const login = (token, rememberMe) => {
    saveToken(token, rememberMe);
    const decodedToken = jwtDecode(token);
    setIsAuthenticated(true);
    setUser(decodedToken); // Set the user state on login

    console.log("User role on login:", decodedToken.role); // Log the user's role on login

    if (!socket) {
      // Establish socket connection on login
      socket = io('https://academy.innovatorsforge.com'); // Replace with your server URL

      socket.on('connect', () => {
        console.log('Socket connected:', socket.id);

        // Join the user's room
        socket.emit('joinRoom', decodedToken.id);
        console.log(`Emitting joinRoom for user ${decodedToken.id}`);
      });
    }
  };

  const logout = () => {
    removeToken();
    setIsAuthenticated(false);
    setUser(null); // Clear the user state on logout

    // Disconnect the socket on logout
    if (socket) {
      socket.disconnect();
      console.log('Socket disconnected on logout');
      socket = null; // Clear socket state
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
