import React, { useState, useEffect } from "react";
import md5 from "md5"; // Import md5 for Gravatar
import avatar from "assets/img/avatars/avatar11.png";
import banner from "assets/img/profile/banner.png";
import Card from "components/card";
import {
  getUserProfile,
  fetchCurrentDescription,
  fetchPoapDescriptions,
  fetchSpecificStepStatus,
  uploadProfilePic,
} from "api";

// Function to generate Gravatar URL
const getGravatarUrl = (email) => {
  const hash = md5(email.trim().toLowerCase());
  return `https://www.gravatar.com/avatar/${hash}?d=identicon`;
};

const Banner = () => {
  const [user, setUser] = useState({
    name: '',
    team: 'No Team Assigned',
    tasksCompleted: 0,
    poapLevel: 'Explorer',
    poapCount: 0,
    imageUrl: avatar,
  });

  const [hovered, setHovered] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userProfile = await getUserProfile();
        const team = userProfile.user.team;
        const teamId = team ? team.id : null;

        const poapDescriptions = await fetchPoapDescriptions();

        let completedPoaps = [];
        let completedPoapCount = 0;
        let poapLevel = 'Explorer';
        let tasksCompleted = 0;

        if (teamId) {
          const currentDescription = await fetchCurrentDescription(teamId);
          const currentDescriptionId = currentDescription.descriptionId;

          completedPoaps = poapDescriptions.filter(poap => poap.id < currentDescriptionId);
          completedPoapCount = completedPoaps.length;
          poapLevel = completedPoaps.length > 0 ? completedPoaps[completedPoaps.length - 1].title : 'Explorer';

          for (const poap of poapDescriptions) {
            for (const category of poap.categories) {
              for (const step of category.steps) {
                const stepStatus = await fetchSpecificStepStatus(teamId, poap.id, step.id);
                if (stepStatus.status === 'completed') {
                  tasksCompleted++;
                }
              }
            }
          }
        }

        setUser({
          name: `${userProfile.user.firstName} ${userProfile.user.lastName}`,
          team: team ? team.name : 'No Team Assigned',
          tasksCompleted,
          poapLevel,
          poapCount: completedPoapCount,
          imageUrl: userProfile.user.imageUrl || getGravatarUrl(userProfile.user.email), // Use Gravatar as fallback
        });
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('profilePic', selectedFile);

    try {
      const response = await uploadProfilePic(formData);
      setUser((prevState) => ({
        ...prevState,
        imageUrl: response.imageUrl,
      }));

      setSelectedFile(null);
    } catch (error) {
      console.error("Error uploading profile picture:", error);
    }
  };

  return (
    <Card extra="items-center w-full h-full p-4 bg-cover">
      <div
        className="relative mt-1 flex h-32 w-full justify-center rounded-xl bg-cover"
        style={{ backgroundImage: `url(${banner})` }}
      >
        <div
          className="absolute -bottom-12 flex h-[87px] w-[87px] items-center justify-center rounded-full border-[4px] border-white bg-pink-400 dark:!border-navy-700"
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <img className="h-full w-full rounded-full" src={user.imageUrl} alt="" />
          {hovered && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full">
              <label className="cursor-pointer text-white">
                <span>Change</span>
                <input
                  type="file"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </label>
            </div>
          )}
        </div>
      </div>

      <div className="mt-16 flex flex-col items-center text-center">
        <h4 className="text-lg font-bold text-navy-700 dark:text-white md:text-xl">
          {user.name}
        </h4>
        <h5 className="text-sm font-normal text-gray-600 dark:text-gray-300 md:text-base">{user.team}</h5>
      </div>

      <div className="mt-6 mb-3 flex flex-col md:flex-row gap-4 justify-center md:gap-8 lg:gap-10 xl:gap-16">
        <div className="flex flex-col items-center justify-center">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white md:text-2xl">
            {user.tasksCompleted}
          </h4>
          <p className="text-xs font-normal text-gray-600 dark:text-gray-300 md:text-sm">Completed</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white md:text-2xl">
            {user.poapCount}
          </h4>
          <p className="text-xs font-normal text-gray-600 dark:text-gray-300 md:text-sm">POAPs</p>
        </div>
        <div className="flex flex-col items-center justify-center">
          <h4 className="text-xl font-bold text-navy-700 dark:text-white md:text-2xl">
            {user.poapLevel}
          </h4>
          <p className="text-xs font-normal text-gray-600 dark:text-gray-300 md:text-sm">POAP Level</p>
        </div>
      </div>
    </Card>
  );
};

export default Banner;
