import React, { useState, useEffect } from "react";
import { FaSchool } from "react-icons/fa";
import Card from "components/card";
import { getUserProfile, fetchTeams } from "api";

const MentorInfo = () => {
  const [user, setUser] = useState({
    school: '',
    teams: []
  });

  useEffect(() => {
    const fetchMentorData = async () => {
      try {
        // Fetch user profile to get the school
        const userProfile = await getUserProfile();
        const school = userProfile.user.school;

        // Fetch all teams and filter them by the school
        const teams = await fetchTeams();
        const filteredTeams = teams.filter(team => team.schoolName === school);

        setUser({ school, teams: filteredTeams });
      } catch (error) {
        console.error("Error fetching mentor data", error);
      }
    };

    fetchMentorData();
  }, []);

  return (
    <Card extra="w-full h-full p-6">
      <div className="flex flex-col items-center justify-center w-full h-full rounded-xl border-[2px] border-dashed border-gray-200 dark:border-navy-700 p-6">
        <FaSchool className="text-6xl text-brand-500 dark:text-white mb-4" />
        <h4 className="text-2xl font-bold text-navy-700 dark:text-white mb-4">
          {user.school}
        </h4>

        {user.teams.length > 0 ? (
          <div className="w-full">
            <h5 className="text-xl font-semibold text-gray-700 dark:text-gray-200 mb-4 text-center">
              Teams
            </h5>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
              {user.teams.map((team) => (
                <div
                  key={team.id}
                  className="flex flex-col items-center justify-center p-4 bg-gray-100 dark:bg-navy-700 rounded-lg shadow-md"
                >
                  <p className="text-lg font-medium text-navy-700 dark:text-white">
                    {team.name}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <p className="text-xl font-medium text-gray-600 dark:text-gray-300 mt-4">
            No teams found for this school.
          </p>
        )}
      </div>
    </Card>
  );
};

export default MentorInfo;
