import React, { useState, useEffect } from 'react';
import { IoSearchOutline, IoPersonAddOutline, IoCloseOutline, IoCheckmarkCircle, IoAlertCircle } from 'react-icons/io5';
import { searchUsersBySchool, addMembersToTeam, getUserProfile } from 'api';

const SearchModal = ({ onClose }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [statusMessage, setStatusMessage] = useState({ type: '', message: '' });

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await getUserProfile();
        setCurrentUser(response.user);
        setTeamId(response.user.team?.id);
      } catch (error) {
        console.error('Error fetching user profile:', error);
      }
    };

    fetchUserProfile();
  }, []);

  const handleSearchChange = async (event) => {
    setSearchTerm(event.target.value);
    if (event.target.value.trim() === '') {
      setSearchResults([]);
      return;
    }

    try {
      const users = await searchUsersBySchool(currentUser?.school, event.target.value);
      const filteredUsers = users.filter(user => user.username !== currentUser?.username);
      setSearchResults(filteredUsers);
    } catch (error) {
      console.error('Error searching users:', error);
      setSearchResults([]);
    }
  };

  const handleAddPerson = async (personId) => {
    try {
      await addMembersToTeam(teamId, [personId]);
      setStatusMessage({ type: 'success', message: 'Person added to the team successfully!' });
      setTimeout(() => setStatusMessage({ type: '', message: '' }), 3000); // Clear message after 3 seconds
    } catch (error) {
      setStatusMessage({ type: 'error', message: 'Error adding person to the team.' });
      setTimeout(() => setStatusMessage({ type: '', message: '' }), 3000); // Clear message after 3 seconds
      console.error('Error adding person to the team:', error);
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-600 bg-opacity-70 overflow-y-auto">
      <div className="bg-white rounded-lg w-11/12 max-w-lg animate-slideInUp">
        <div className="flex items-center justify-between p-4 border-b border-gray-200">
          <h3 className="text-lg font-semibold">Add Team Member</h3>
          <button onClick={onClose} className="focus:outline-none">
            <IoCloseOutline className="text-lg" />
          </button>
        </div>
        <div className="px-4 py-2">
          <div className="relative">
            <IoSearchOutline className="absolute left-3 top-1/2 transform -translate-y-1/2 text-lg text-gray-400" />
            <input
              type="text"
              className="pl-10 pr-4 py-2 w-full rounded-lg border border-gray-300 focus:border-blue-500 focus:outline-none"
              placeholder="Search for a person..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="p-4 overflow-y-auto max-h-96">
          {searchResults.map((person) => (
            <div key={person.id} className="flex items-center justify-between p-4 rounded-lg hover:bg-blue-100 cursor-pointer">
              <div className="flex items-center">
                <div className="mr-4">
                  <img src={person.imageUrl || 'https://via.placeholder.com/150'} alt="" className="w-12 h-12 rounded-full object-cover shadow-lg" />
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-800">{person.username}</p>
                </div>
              </div>
              <button
                className="rounded-xl bg-orange-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-orange-400 dark:text-white dark:hover:bg-orange-300 dark:active:bg-orange-200"
                onClick={() => handleAddPerson(person.id)}
              >
                <IoPersonAddOutline className="inline mr-2" />Add
              </button>
            </div>
          ))}
          {searchResults.length === 0 && searchTerm.trim() !== '' && (
            <div className="p-4 text-center text-red-500">No users found</div>
          )}
        </div>
        {statusMessage.message && (
          <div className={`p-4 text-center ${statusMessage.type === 'success' ? 'text-green-500' : 'text-red-500'}`}>
            {statusMessage.type === 'success' ? (
              <IoCheckmarkCircle className="inline mr-2" />
            ) : (
              <IoAlertCircle className="inline mr-2" />
            )}
            {statusMessage.message}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchModal;
