import React from 'react';
import { FaTrophy, FaMedal, FaStar } from 'react-icons/fa';  // Import icons

const AchievementHighlights = () => {
  const achievements = [
    { team: 'Red Rockets', achievement: 'Best Performance in July', icon: <FaTrophy className="text-red-500" /> },
    { team: 'Blue Barracudas', achievement: 'Most Improved Team', icon: <FaMedal className="text-blue-500" /> },
    { team: 'Green Gators', achievement: 'Top Newcomer', icon: <FaStar className="text-green-500" /> }
  ];

  return (
    <div className="mt-10 p-4 bg-white rounded-lg shadow-lg dark:bg-navy-800 dark:shadow-none">
      <h3 className="text-lg font-bold text-center text-gray-800 dark:text-white mb-4">Achievement Highlights</h3>
      <div className="flex flex-col items-center">
        {achievements.map((item, index) => (
          <div key={index} className="flex items-center w-full p-2 my-1 bg-gray-100 rounded-md shadow dark:bg-navy-700 dark:shadow-none">
            <div className="p-3 rounded-full bg-opacity-20 text-xl dark:bg-opacity-20 dark:bg-gray-500">
              {item.icon}
            </div>
            <div className="ml-4 text-gray-700 dark:text-gray-300">
              <p className="text-sm font-bold dark:text-white">{item.team}</p>
              <p className="text-xs">{item.achievement}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AchievementHighlights;
