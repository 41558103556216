import React, { useState, useEffect } from 'react';
import { createTeam, searchUsersBySchool } from 'api';
import debounce from 'lodash.debounce';

const CreateTeam = ({ isOpen, onClose }) => {
  const [teamName, setTeamName] = useState(localStorage.getItem('teamName') || '');
  const [schoolName, setSchoolName] = useState(localStorage.getItem('schoolName') || '');
  const [selectedMembers, setSelectedMembers] = useState(JSON.parse(localStorage.getItem('selectedMembers')) || []);
  const [searchResults, setSearchResults] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [noUsersFound, setNoUsersFound] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    localStorage.setItem('teamName', teamName);
    localStorage.setItem('schoolName', schoolName);
    localStorage.setItem('selectedMembers', JSON.stringify(selectedMembers));
  }, [teamName, schoolName, selectedMembers]);

  const handleCreateTeam = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    try {
      const teamData = {
        name: teamName,
        schoolName: schoolName,
        members: selectedMembers.map(member => member.id)
      };
      await createTeam(teamData);
      console.log('Team created successfully');
      localStorage.removeItem('teamName');
      localStorage.removeItem('schoolName');
      localStorage.removeItem('selectedMembers');
      onClose(); // Close the modal on success
    } catch (error) {
      console.error('Error creating team:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleSearchUsers = async (school) => {
    if (school.trim() === '') {
      setSearchResults([]);
      setNoUsersFound(false);
      return;
    }
    setIsLoading(true);
    try {
      const users = await searchUsersBySchool(school);
      setSearchResults(users);
      setNoUsersFound(users.length === 0);
    } catch (error) {
      console.error('Error searching users:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const debouncedSearch = debounce((value) => handleSearchUsers(value), 500);

  const handleSchoolNameChange = (e) => {
    const { value } = e.target;
    setSchoolName(value);
    debouncedSearch(value);
  };

  const handleSelectMember = (user) => {
    if (!selectedMembers.find(member => member.id === user.id)) {
      setSelectedMembers([...selectedMembers, user]);
    }
  };

  const handleRemoveMember = (userId) => {
    setSelectedMembers(selectedMembers.filter(member => member.id !== userId));
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-75 overflow-y-auto h-full w-full flex justify-center items-center">
      <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full mx-4">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-medium">Create New Team</h3>
          <button onClick={onClose} className="text-gray-800 text-xl font-semibold hover:text-gray-600">&times;</button>
        </div>
        <form onSubmit={handleCreateTeam} className="space-y-6">
          <div>
            <label htmlFor="teamName" className="block text-sm font-medium text-gray-700">Team Name</label>
            <input
              type="text"
              id="teamName"
              name="teamName"
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500"
            />
          </div>
          <div>
            <label htmlFor="schoolName" className="block text-sm font-medium text-gray-700">School Name</label>
            <input
              type="text"
              id="schoolName"
              name="schoolName"
              value={schoolName}
              onChange={handleSchoolNameChange}
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500"
            />
          </div>
          {isLoading && (
            <div className="flex justify-center mt-2">
              <svg className="animate-spin h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"></path>
              </svg>
            </div>
          )}
          {noUsersFound && !isLoading && (
            <div className="text-red-500 text-sm mt-2">No users found for the specified school name.</div>
          )}
          <div>
            <label htmlFor="searchResults" className="block text-sm font-medium text-gray-700">Search Results</label>
            <ul className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
              {searchResults.map(user => (
                <li key={user.id} className="flex justify-between items-center">
                  <span>{user.username} ({user.email})</span>
                  <button type="button" onClick={() => handleSelectMember(user)} className="ml-2 bg-green-500 px-2 py-1 text-white rounded">Add</button>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <label htmlFor="selectedMembers" className="block text-sm font-medium text-gray-700">Selected Members</label>
            <ul className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm">
              {selectedMembers.map(user => (
                <li key={user.id} className="flex justify-between items-center">
                  <span>{user.username} ({user.email})</span>
                  <button type="button" onClick={() => handleRemoveMember(user.id)} className="ml-2 bg-red-500 px-2 py-1 text-white rounded">Remove</button>
                </li>
              ))}
            </ul>
          </div>
          <button type="submit" disabled={isSubmitting} className={`w-full bg-orange-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-orange-400 dark:text-white dark:hover:bg-orange-300 dark:active:bg-orange-200 ${isSubmitting ? 'opacity-50 cursor-not-allowed' : ''}`}>
            {isSubmitting ? 'Creating...' : 'Create Team'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default CreateTeam;
