import React, { useState, useEffect } from 'react';
import DashboardHeader from './components/DashboardHeader';
import StatsCard from './components/StatsCard';
import ChartSection from './components/ChartSection';
import StudentList from './components/StudentList';
import SchoolComparisonTable from './components/SchoolComparisonTable';
import InboxCard from './components/InboxCard';
import TimeLine from 'views/admin/main/applications/Calendar/components/TimeLine';
import { getUserProfile, fetchTeams, fetchCurrentDescription, fetchTeamStepStatus, fetchTeamPointsInfo } from 'api';

const MentorHub = () => {

  const [ schoolName, setSchoolName ] = useState('');
  const [ teams, setTeams ] = useState([]);
  const [ teamData, setTeamData ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [ mentorId, setMentorId ] = useState(null);

  useEffect(() => {
    const fetchMentorData = async () => {
      try {
        // Fetch the user's profile to get the school name
        const profile = await getUserProfile();
        const school = profile.user?.school;
        const mentorId = profile.user?.id;
        setSchoolName(school);
        setMentorId(mentorId);

        if (school) {
          // Fetch teams associated with the school
          const allTeams = await fetchTeams();
          const filteredTeams = allTeams.filter(team => team.schoolName === school);

          setTeams(filteredTeams);

          const teamDataPromises = filteredTeams.map(async (team) => {
            const currentDescription = await fetchCurrentDescription(team.id);
            const stepStatuses = await fetchTeamStepStatus(team.id, currentDescription.descriptionId);
            const pointsInfo = await fetchTeamPointsInfo(team.id, currentDescription.descriptionId);

            return {
              team,
              currentDescription,
              stepStatuses,
              pointsInfo,
            };
          });

          const resolvedTeamData = await Promise.all(teamDataPromises);
          setTeamData(resolvedTeamData);
        }
      } catch (error) {
        console.error("Error fetching mentor data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMentorData();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // You can replace this with a loading spinner or a similar component
  }


  // Calculate the stats based on the teams
  const totalTeams = teams.length;
  const projectsInProgress = teamData.filter(data => data.stepStatuses.some(step => step.status === 'completed')).length;
  const completedProjects = teamData.filter(data => data.stepStatuses.every(step => step.status === 'completed')).length;


  return (
    <div className="mt-3 grid h-full w-full gap-5 rounded-[20px]">
      

      {/* Stats Cards */}
      <div className="col-span-12 grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-4">
        <StatsCard 
          title="Total Teams" 
          value={totalTeams} 
          icon="MdGroup" 
          bgColor="bg-lightPrimary dark:bg-navy-600" 
          iconColor="text-navy-700 dark:text-white"
        />
        <StatsCard 
          title="Projects in Progress" 
          value={projectsInProgress}
          icon="MdAutorenew" 
          bgColor="bg-lightPrimary dark:bg-navy-600" 
          iconColor="text-navy-700 dark:text-white"
        />
        <StatsCard 
          title="Teams Behind Schedule" 
          value="0" 
          icon="MdAccessTime" 
          bgColor="bg-lightPrimary dark:bg-navy-600" 
          iconColor="text-navy-700 dark:text-white"
        />
        <StatsCard 
          title="Completed Projects" 
          value={completedProjects} 
          icon="MdDoneAll" 
          bgColor="bg-lightPrimary dark:bg-navy-600" 
          iconColor="text-navy-700 dark:text-white"
        />
      </div>

      {/* Main Content */}
      <div className="col-span-12 grid grid-cols-1 gap-5 lg:grid-cols-3">
        {/* Left Column: ChartSection */}
        <div className="col-span-2 grid gap-5">
          <TimeLine teams= {teams}/>          
          <StudentList teamData={teamData}/>
        </div>

        {/* Right Column: SchoolComparisonTable and InboxCard */}
        <div className="col-span-1 grid gap-5">
          <ChartSection mentorId={mentorId} teams={teams}/>
          <InboxCard />
        </div>
      </div>
    </div>
  );
};

export default MentorHub;
