import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdChevronLeft, MdChevronRight, MdPersonAdd, MdAddBox, MdGroupAdd } from "react-icons/md";
import { useGlobalFilter, usePagination, useSortBy, useTable } from "react-table";
import Card from "components/card";
import SearchModal from "./SearchModal";
import CreateTeam from "./CreateTeam";
import ErrorAlerts from "components/alerts/ErrorAlerts";

const UserReportsTable = (props) => {
  const { columnsData, tableData, isModalOpen, toggleModal, selectedTeam, userProfile } = props;
  const [isCreateTeamModalOpen, setCreateTeamModalOpen] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    nextPage,
    previousPage,
    initialState,
    setPageSize,
    state,
  } = tableInstance;
  initialState.pageSize = 6;

  const { pageSize } = state;

  const navigate = useNavigate();

  const handleNewProject = () => {
    navigate("/admin/new-project");
  };

  const handleCreateTeam = () => {
    if (userProfile?.user?.teamRole === 'Team Leader' && selectedTeam) {
      setErrorMessages(["You are already leading a team and cannot create another."]);
    } else {
      navigate("/admin/create-team");
    }
  };

  const handleCloseCreateTeam = () => {
    setCreateTeamModalOpen(false);
  };

  const handleCloseErrorAlert = () => {
    setErrorMessages([]);
  };

  // Determine if the current user is the team leader or does not belong to any team
  const canAccessButtons = userProfile?.user?.teamRole === 'Team Leader' || !selectedTeam;

  return (
    <>
      {errorMessages.length > 0 && (
        <ErrorAlerts errors={errorMessages} onClose={handleCloseErrorAlert} />
      )}
      <Card extra="w-full px-3 overflow-x-auto lg:overflow-x-hidden">
        <div className="mb-4 flex flex-col lg:flex-row justify-end space-y-4 lg:space-y-0 lg:space-x-4 mt-4">
          {canAccessButtons && (
            <>
              <button
                onClick={handleCreateTeam}
                className="flex items-center justify-center space-x-2 rounded-xl bg-orange-500 px-5 py-3 text-base font-medium text-white transition duration-200 hover:bg-orange-600 active:bg-orange-700 dark:bg-orange-400 dark:text-white dark:hover:bg-orange-300 dark:active:bg-orange-200"
              >
                <MdGroupAdd />
                <span>Create Team</span>
              </button>
              <button
                onClick={toggleModal}
                className="flex items-center justify-center space-x-2 rounded-xl border-2 border-orange-500 px-5 py-3 text-base font-medium text-orange-500 transition duration-200 hover:bg-orange-600/5 active:bg-orange-700/5 dark:border-orange-400 dark:bg-orange-400/10 dark:text-white dark:hover:bg-orange-300/10 dark:active:bg-orange-200/10"
              >
                <MdPersonAdd />
                <span>Add Member</span>
              </button>
              <button
                onClick={handleNewProject}
                className="flex items-center justify-center space-x-2 rounded-xl bg-gradient-to-br from-[#FFC837] to-[#FF6108] px-5 py-3 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#FFC837]/50"
              >
                <MdAddBox />
                <span>New Project</span>
              </button>
            </>
          )}
        </div>

        {selectedTeam ? (
          <table {...getTableProps()} className="w-full">
            <thead className="w-full">
              {headerGroups.map((headerGroup, index) => (
                <tr
                  className="items-center border-b border-gray-200 dark:!border-white/10"
                  {...headerGroup.getHeaderGroupProps()}
                  key={index}
                >
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="px-[19px] pt-[32px] pb-[12px] text-left text-xs tracking-wide text-gray-600"
                      key={index}
                    >
                      <div className="text-xs font-bold">
                        {column.render("Header")}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="w-full">
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    className="border-b border-gray-200 dark:!border-white/10"
                    {...row.getRowProps()}
                    key={index}
                  >
                    {row.cells.map((cell, index) => {
                      let data = "";
                      if (cell.column.Header === "FULL NAME") {
                        data = (
                          <div className="flex w-full items-center gap-[14px]">
                            <div className="flex h-[60px] w-[60px] items-center justify-center rounded-full bg-blue-300">
                              <img
                                className="h-full w-full rounded-full"
                                src={cell.value.imageUrl}
                                alt=""
                              />
                            </div>
                            <p className="font-medium text-navy-700 dark:text-white">
                              {" "}
                              {cell.value}
                            </p>
                          </div>
                        );
                      } else {
                        data = (
                          <div className="w-full text-base font-medium text-navy-700 dark:text-white">
                            {cell.value}
                          </div>
                        );
                      }
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={index}
                          className="mt-[20px] px-4 py-[16px]"
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p className="text-center text-lg text-gray-600 mb-5">
            Please join a team to see the details.
          </p>
        )}
      </Card>

      {isCreateTeamModalOpen && <CreateTeam isOpen={isCreateTeamModalOpen} onClose={handleCloseCreateTeam} />}
      {isModalOpen && <SearchModal onClose={toggleModal} />}
    </>
  );
};

export default UserReportsTable;
