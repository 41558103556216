import React, { useState } from "react";
import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import { resetPassword } from "api";
import { useSearchParams } from "react-router-dom";

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams] = useSearchParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    try {
      const token = searchParams.get("token");
      const response = await resetPassword(token, newPassword);
      setMessage(response.message);
      setError("");
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred");
      setMessage("");
    }
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Default
      maincard={
        <div className="flex h-full w-full items-center justify-center md:mx-0 md:px-0 lg:mb-10 lg:items-start lg:justify-start">
          <div className="mt-[12vh] flex flex-col rounded-[20px] pb-12 pt-10 px-6 md:px-10 lg:max-w-[70%] xl:max-w-[42%] shadow-lg bg-white dark:bg-navy-800">
            <h2 className="mb-4 text-3xl font-bold text-navy-700 dark:text-white">
              Reset Your Password
            </h2>
            <p className="mb-8 text-sm text-gray-500 dark:text-gray-300">
              Enter your new password below and confirm it to reset your password.
            </p>
            {message && <p className="mb-4 text-green-500">{message}</p>}
            {error && <p className="mb-4 text-red-500">{error}</p>}
            <form onSubmit={handleSubmit} className="space-y-6">
              <InputField
                variant="auth"
                label="New Password"
                placeholder="Enter your new password"
                id="newPassword"
                type={showPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <InputField
                variant="auth"
                label="Confirm Password"
                placeholder="Confirm your new password"
                id="confirmPassword"
                type={showPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div className="flex items-center space-x-3">
                <input
                  type="checkbox"
                  id="showPassword"
                  checked={showPassword}
                  onChange={toggleShowPassword}
                  className="form-checkbox h-4 w-4 text-brand-500 dark:text-brand-400"
                />
                <label
                  htmlFor="showPassword"
                  className="text-sm text-gray-600 dark:text-gray-400"
                >
                  Show Password
                </label>
              </div>
              <button
                type="submit"
                className="w-full rounded-xl bg-orange-400 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-900 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
              >
                Reset Password
              </button>
            </form>
          </div>
        </div>
      }
    />
  );
}

export default ResetPassword;
