import axios from "axios";
import axiosInstance from "./axiosInstance.js";

// Fetch timelines by teamId
export const fetchTimelines = async (schoolName) => {
  try {
    const response = await axiosInstance.get(`/api/timelines`, {
      params: { schoolName }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching timelines:", error);
    return [];
  }
};


// Update an existing timeline
export const updateTimeline = async (timelineId, timelineData) => {
  try {
    const response = await axiosInstance.put(`/api/timelines/${timelineId}`, timelineData);
    return response.data;
  } catch (error) {
    console.error("Error updating timeline:", error);
    throw error;
  }
};

// Delete a timeline
export const deleteTimeline = async (timelineId) => {
  try {
    const response = await axiosInstance.delete(`/api/timelines/${timelineId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting timeline:", error);
    throw error;
  }
};


//Fetch the logged-in users profile
export const getUserProfile = async () => {
  try {
    const response = await axiosInstance.get('/api/users/profile');
    return response.data;
  } catch (error) {
    console.error("Error fetching user profile", error);
    throw error;
  }
};

// Change user's password
export const updatePassword = async (userId, { oldPassword, newPassword }) => {
  try {
    const response = await axiosInstance.put(`/api/users/${userId}/update-password`, { oldPassword, newPassword });
    return response.data;
  } catch (error) {
    console.error("Error changing password:", error);
    throw error;
  }
};

// Toggle Two-Factor Authentication
export const toggleTwoFactorAuth = async (userId, { isEnabled }) => {
  try {
    const response = await axiosInstance.put(`/api/users/${userId}/two-factor-auth`, { isEnabled });
    return response.data;
  } catch (error) {
    console.error("Error toggling Two-Factor Authentication:", error);
    throw error;
  }
};



// Search users by school
export const searchUsersBySchool = async (schoolName, username) => {
  try {
    const response = await axiosInstance.get('/api/users/search', {
      params: { schoolName, username }
    });
    return response.data;
  } catch (error) {
    console.error("Error searching users by school:", error);
    return [];
  }
};

// Upload user's profile picture
export const uploadProfilePic = async (formData) => {
  try {
    const response = await axiosInstance.post('/api/users/upload-profile-pic', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading profile picture:", error);
    throw error;
  }
};



//Fetch team-members for logged-in user
export const fetchTeamMembers = async (teamId) => {
  try {
    const response = await axiosInstance.get(`/api/teams/${teamId}/members`);
    return response.data;
  } catch (error) {
    console.error("Error fetching team members:", error.response ? error.response.data : error.message);
    throw error;
  }
};

// Fetch a specific user by ID
export const fetchUserById = async (userId) => {
  try {
    const response = await axiosInstance.get(`/api/users/${userId}`);
    return response.data;
  } catch (error) {
    console.log("Error fetching user details:", error);
    return null;
  }
};

export const fetchUsersBySchool = async (schoolName) => {
  try {
    const response = await axiosInstance.get(`/api/users/school/${encodeURIComponent(schoolName)}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching users by school:", error);
    throw error;
  }
};

// Fetch messages from a specific user
export const fetchMessages = async (userId) => {
  try {
    const response = await axiosInstance.get(`/api/messages/user/${userId}`);
    return response.data;
  } catch (error) {
    console.log("Error fetching messages:", error);
    return [];
  }
};

//Create a new Message
export const createMessage = async (messageData) => {
  try {
    const response = await axiosInstance.post(`/api/messages`, messageData);
    return response.data;
  } catch (error) {
    console.error("Error creating message", error);
    throw error;
  }
};

export const fetchUnreadMessages = async (userId) => {
  try {
    const response = await axiosInstance.get(`/api/messages/unread/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching unread messages:", error);
    return [];
  }
};


export const markMessagesAsRead = async (messageIds) => {
  try {
    const response = await axiosInstance.post(`/api/messages/markAsRead`, { messageIds });
    return response.data;
  } catch (error) {
    console.error("Error marking messages as read:", error);
    throw error;
  }
};

// Team ranking
export const fetchTeamRankings = async () => {
  try {
    const response = await axiosInstance.get(`/api/teams/ranked`);
    return response.data;
  } catch (error) {
    console.error("Error fetching rankings:", error);
    return [];
  }
};


// Add members to a team
export const addMembersToTeam = async (teamId, memberIds) => {
  try {
    const response = await axiosInstance.post(`/api/teams/${teamId}/add-members`, { memberIds });
    return response.data;
  } catch (error) {
    console.error("Error adding members to team:", error);
    throw error;
  }
};

// Check if team name exists
export const checkTeamName = async (name) => {
  try {
    const response = await axiosInstance.get('/api/teams/checkTeamName', { params: { name } });
    return response.data;
  } catch (error) {
    console.error("Error checking team name:", error);
    throw error;
  }
};


// Fetch tasks for teams
export const fetchUniversalTasks = async () => {
  try {
    const response = await axiosInstance.get(`/api/tasks`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tasks:", error);
    return [];
  }
};

// Complete a task for a team
export const completeTaskForTeam = async (taskId, teamId) => {
  try {
    const response = await axiosInstance.post(`/api/tasks/complete`, { taskId, teamId });
    return response.data;
  } catch (error) {
    console.error("Error completing task:", error);
    throw error;
  }
};

// Fetch team specific task completion status
export const fetchTeamTaskStatuses = async (teamId) => {
  try {
    const response = await axiosInstance.get(`/api/teams/${teamId}/task-status`);
    return response.data;
  } catch (error) {
    console.error("Error fetching team status", error);
    return [];
  }
};



// Fetch all POAP descriptions
export const fetchPoapDescriptions = async () => {
  try {
    const response = await axiosInstance.get(`/api/poap-descriptions`);
    return response.data;
  } catch (error) {
    console.error("Error fetching POAP descriptions:", error);
    return [];
  }
};

// Fetch a specific POAP description by ID
export const fetchPoapDescriptionById = async (id) => {
  try {
    const response = await axiosInstance.get(`/api/poap-descriptions/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching POAP description:", error);
    return null;
  }
};

// Fetch TeamStep Statuses
export const fetchTeamStepStatus = async (teamId, descriptionId) => {
    try {
        const response = await axiosInstance.get(`/api/poap-descriptions/teams/${teamId}/poap-descriptions/${descriptionId}/steps-status`);
        return response.data;
    } catch (error) {
        console.error("Error fetching team step statuses", error);
        throw error;
    }
};

//Complete TeamSteps
export const completeStep = async (teamId, stepId, descriptionId) => {
  try {
    const response = await axiosInstance.post(`/api/poap-descriptions/poap/completeStep/${teamId}/${stepId}`, { descriptionId });
    return response.data;
  } catch (error) {
    console.error("Error completing step:", error);
    throw error;
  }
};

// Fetch team points info
export const fetchTeamPointsInfo = async (teamId, descriptionId) => {
  try {
    const response = await axiosInstance.get(`/api/poap-descriptions/points-info/${teamId}/${descriptionId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching team points info:", error);
    throw error;
  }
};

//Fetch current DescriptionId
export const fetchCurrentDescription = async (teamId) => {
  try {
    const response = await axiosInstance.get(`/api/poap-descriptions/poap/currentDescription/${teamId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching current description:", error);
    throw error;
  }
};

// Subscribe to newsletter
export const subscribeToNewsletter = async (email) => {
  try {
    const response = await axiosInstance.post('/api/subscribe', { email });
    return response.data;
  } catch (error) {
    console.error("Error subscribing to newsletter:", error);
    throw error;
  }
};

export const fetchSpecificStepStatus = async (teamId, descriptionId, poapStepId) => {
    try {
      const response = await axiosInstance.get(`/api/poap-descriptions/teams/${teamId}/poap-descriptions/${descriptionId}/steps/${poapStepId}/status`);
      return response.data;
    } catch (error) {
      console.error("Error fetching specific description", error)
      throw error
    }
    
};

export const fetchProjectStatus = async (teamId, descriptionId) => {
  try {
      const response = await axiosInstance.get(`/api/poap-descriptions/teams/${teamId}/poap-descriptions/${descriptionId}/status`);
      return response.data;
  } catch (error) {
      console.error("Error fetching project status", error);
      throw error;
  }
};

// Fetch all tasks
export const fetchTasks = async () => {
  try {
    const response = await axiosInstance.get(`/api/tasks`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tasks:", error);
    return [];
  }
};

// Fetch tasks for a specific MintLink and Team
export const fetchTasksByMintLinkAndTeam = async (mintLinkId, teamId) => {
  try {
    const response = await axiosInstance.get(`/api/tasks/${mintLinkId}/${teamId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching tasks:", error);
    return [];
  }
};

// Fetch all club points
export const fetchClubPoints = async () => {
  try {
    const response = await axiosInstance.get(`/api/points`);
    return response.data;
  } catch (error) {
    console.error("Error fetching club points:", error);
    return [];
  }
};

// Fetch club points by team ID
export const fetchClubPointsByTeam = async (teamId) => {
  try {
    const response = await axiosInstance.get(`/api/points/team/${teamId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching club points by team ID:", error);
    return [];
  }
};

// Create a new club point
export const createClubPoint = async (clubPointData) => {
  try {
    const response = await axiosInstance.post(`/api/points`, clubPointData);
    return response.data;
  } catch (error) {
    console.error("Error creating club point:", error);
    throw error;
  }
};

// Update a club point
export const updateClubPoint = async (clubPointId, clubPointData) => {
  try {
    const response = await axiosInstance.put(`/api/points/${clubPointId}`, clubPointData);
    return response.data;
  } catch (error) {
    console.error("Error updating club point:", error);
    throw error;
  }
};

// Delete a club point
export const deleteClubPoint = async (clubPointId) => {
  try {
    const response = await axiosInstance.delete(`/api/points/${clubPointId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting club point:", error);
    throw error;
  }
};


//Email Verification
export const handleVerification = async (code) => {
  try {
    const response = await axiosInstance.get(`/api/auth/verify-email`, {
      params: { code }
    });
    return response.data
  } catch (error) {
    console.error("Error verifying email", error);
    throw error;
  }
}

//Resend Verification code
export const resendVerificationCode = async ({ email }) => {
  try {
    const response = await axiosInstance.post(`/api/auth/resend-verification`, {email});
    return response.data;
  } catch (error) {
    console.error("Error resending verification code", error);
    throw error;
  }  
};

//ADMIN FUNCTIONS
// Create a new user
export const createUser = async (userData) => {
  try {
    const response = await axiosInstance.post('/api/users', userData);
    return response.data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

// Fetch Users
export const fetchUsers = async () => {
  try {
    const response = await axiosInstance.get(`/api/users`);
    // Transform the data to match the table's expected format
    return response.data.map(user => ({
      name: [`${user.firstName} ${user.lastName}`,user.email, user.imageUrl],
      email: user.email,
      status: user.status,
      teamId: user.teamId,
      school: user.school,
      teamRole: user.teamRole,
      team: user.team ? user.team.name : "No Team"
    }));
  } catch (error) {
    console.log("Error fetching users:", error);
    return [];
  }
};


// Update an existing user
export const updateUser = async (userId, userData) => {
  try {
    const response = await axiosInstance.put(`/api/users/${userId}`, userData);
    return response.data;
  } catch (error) {
    console.error("Error updating user:", error);
    throw error;
  }
};

// Delete a user
export const deleteUser = async (userId) => {
  try {
    const response = await axiosInstance.delete(`/api/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};

// Fetch all teams
export const fetchTeams = async () => {
  try {
    const response = await axiosInstance.get(`/api/teams`);
    // Ensure the response data is in the expected format
    if (Array.isArray(response.data)) {
      return response.data.map(team => ({
        id: team.id,
        name: team.name,
        description: team.description,
        schoolName: team.schoolName,
        points: team.points,
        currentPoapLevel: team.currentLevels
      }));
    } else {
      return [];
    }
  } catch (error) {
    console.error("Error fetching teams:", error);
    return [];
  }
};

// Create a new team
export const createTeam = async (teamData) => {
  try {
    const response = await axiosInstance.post('/api/teams', teamData);
    return response.data;
  } catch (error) {
    console.error("Error creating team:", error);
    throw error;
  }
};

// Update an existing team
export const updateTeam = async (teamId, teamData) => {
  try {
    const response = await axiosInstance.put(`/api/teams/${teamId}`, teamData);
    return response.data;
  } catch (error) {
    console.error("Error updating team:", error);
    throw error;
  }
};

// Delete a team
export const deleteTeam = async (teamId) => {
  try {
    const response = await axiosInstance.delete(`/api/teams/${teamId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting team:", error);
    throw error;
  }
};

// Create a new timeline
export const createTimeline = async (timelineData) => {
  try {
    const response = await axiosInstance.post(`/api/timelines`, timelineData);
    return response.data;
  } catch (error) {
    console.error("Error creating timeline:", error);
    throw error;
  }
};

// Issue POAPs to teams via mint links
export const issuePoapsToTeam = async (teamId) => {
  try {
    const response = await axiosInstance.post(`/api/teams/${teamId}/issue-poaps`);
    return response.data;
  } catch (error) {
    console.error("Error issuing POAPs to team:", error);
    throw error;
  }
};


// Create a new POAP description
export const createPoapDescription = async (poapData) => {
  try {
    const response = await axiosInstance.post(`/api/poap-descriptions`, poapData);
    return response.data;
  } catch (error) {
    console.error("Error creating POAP description:", error);
    throw error;
  }
};

// Update an existing POAP description
export const updatePoapDescription = async (id, poapData) => {
  try {
    const response = await axiosInstance.put(`/api/poap-descriptions/${id}`, poapData);
    return response.data;
  } catch (error) {
    console.error("Error updating POAP description:", error);
    throw error;
  }
};

// Delete a POAP description
export const deletePoapDescription = async (id) => {
  try {
    const response = await axiosInstance.delete(`/api/poap-descriptions/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting POAP description:", error);
    throw error;
  }
};

// Complete team step

export const completeTeamStep = async (teamId, stepId, descriptionId) => {
  try {
    const response = await axiosInstance.post(`/api/poap-descriptions/teams/${teamId}/steps/${stepId}/complete`, { descriptionId });
    return response.data;
  } catch (error) {
    console.error("Error completing team step:", error);
    throw error;
  }
};

// Fetch notifications for a specific user
export const fetchNotifications = async (userId) => {
  try {
    const response = await axiosInstance.get(`/api/notifications/${userId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching notifications:", error);
    return [];
  }
};

// Mark a notification as read
export const markNotificationAsRead = async (notificationId) => {
  try {
    const response = await axiosInstance.put(`/api/notifications/${notificationId}/read`);
    return response.data;
  } catch (error) {
    console.error("Error marking notification as read:", error);
    throw error;
  }
};

// Create a new notification (This can be used if needed)
export const createNotification = async (notificationData) => {
  try {
    const response = await axiosInstance.post('/api/notifications', notificationData);
    return response.data;
  } catch (error) {
    console.error("Error creating notification:", error);
    throw error;
  }
};

// Forgot Password
export const forgotPassword = async (email) => {
  try {
    const response = await axiosInstance.post('/api/forgot-password', { email });
    return response.data;
  } catch (error) {
    console.error("Error requesting password reset:", error);
    throw error;
  }
};

// Reset Password
export const resetPassword = async (token, newPassword) => {
  try {
    const response = await axiosInstance.post('/api/reset-password', { token, newPassword });
    return response.data;
  } catch (error) {
    console.error("Error resetting password:", error);
    throw error;
  }
};

export const fetchMentorBySchool = async (school) => {
  try {
    const response = await axiosInstance.get(`/api/teams/schools/${encodeURIComponent(school)}/mentor`);
    return response.data.mentorId;
  } catch (error) {
    console.error("Error fetching mentor by school:", error);
    throw error;
  }
};