import React, { useState, useEffect } from "react";
import Banner from "./components/Banner";
import General from "./components/General";
import Notification from "./components/Notification";
import Project from "./components/Project";
import Storage from "./components/Storage";
import Upload from "./components/Upload";
import { useNavigate } from "react-router-dom"; // Import the useNavigate hook
import { MdSettings } from "react-icons/md"; // Import a settings icon from react-icons
import MentorBanner from "./components/MentorBanner";
import MentorInfo from "./components/MentorInfo";
import MentorNotifications from "./components/MentorNotifications";
import MentorsBadge from "./components/MentorsBadge";
import { getUserProfile } from "api"; // Import the API call to get user profile

const ProfileOverview = () => {
  const navigate = useNavigate(); // Initialize useNavigate
  const [role, setRole] = useState(null); // State to store the user role

  useEffect(() => {
    const fetchUserRole = async () => {
      try {
        const userProfile = await getUserProfile(); // Fetch user profile
        setRole(userProfile.user.role); // Assuming the role is stored in userProfile.user.role
      } catch (error) {
        console.error("Error fetching user role:", error);
      }
    };

    fetchUserRole();
  }, []);

  const handleSettingsClick = () => {
    navigate("/admin/main/account/settings"); // Replace with your actual settings route
  };

  if (role === null) {
    return <p>Loading...</p>; // Show a loading state while fetching the role
  }

  return (
    <div className="flex w-full flex-col gap-5 lg:gap-5">
      <div className="w-full mt-3 flex h-fit flex-col gap-5 lg:grid lg:grid-cols-12">
        <div className="col-span-4 lg:!mb-0">
          {role === "mentor" ? <MentorBanner /> : <Banner />}
        </div>

        <div className="col-span-3 lg:!mb-0">
          {role ==="mentor" ? <MentorsBadge /> : <Storage />}
        </div>
        <div className="z-0 col-span-5 lg:!mb-0">
          {role === "mentor" ? <MentorInfo /> : <Upload />}
        </div>
      </div>

      <div className="mb-4 grid h-full grid-cols-1 gap-5 lg:!grid-cols-12">
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
          <Project />
        </div>
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-5">
          <General />
        </div>

        <div className="col-span-5 lg:col-span-12 lg:mb-0 3xl:!col-span-3">
          {role === "mentor" ? <MentorNotifications /> : <Notification />}
        </div>
      </div>

      <div className="flex justify-end mt-5">
        <button
          onClick={handleSettingsClick}
          className="flex items-center justify-center rounded-xl bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:hover:bg-brand-300 dark:active:bg-brand-200"
        >
          <MdSettings className="mr-2 text-xl" /> Change Settings
        </button>
      </div>
    </div>
  );
};

export default ProfileOverview;
