import Milestone from "components/card/Milestone";
import MiniCalendar from "components/calendar/MiniCalendar";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import firstPOAP from "../../../../../assets/img/poaps/explorerPOAP.png";
import secondPOAP from "../../../../../assets/img/poaps/shapeShifterPOAP.png";
import thirdPOAP from "../../../../../assets/img/poaps/masterFinsherPOAP.png";
import fourthPOAP from "../../../../../assets/img/poaps/creatorPOAP.png";
import { fetchPoapDescriptions, fetchTeamStepStatus, getUserProfile } from "api";

import Schedule from "./components/Schedule";
import Hours from "./components/Hours";
import Card from "components/card";

const ProjectMilestones = () => {
  const navigate = useNavigate();
  const [toggleState, setToggleState] = useState(1);
  const [poapDescriptions, setPoapDescriptions] = useState([]);
  const [teamId, setTeamId] = useState(null);
  const [completionStatus, setCompletionStatus] = useState({});
  const [userHasTeam, setUserHasTeam] = useState(false);

  // Helper function to get the correct POAP image
  const getPoapImage = (id) => {
    switch (id) {
      case 1:
        return firstPOAP;
      case 2:
        return secondPOAP;
      case 3:
        return thirdPOAP;
      case 4:
        return fourthPOAP;
      default:
        return null;
    }
  };

  useEffect(() => {
    const loadPoapDescriptions = async () => {
      try {
        const userProfile = await getUserProfile();
        const userTeamId = userProfile.user.team?.id;
        setTeamId(userTeamId);
        setUserHasTeam(!!userTeamId); // Check if user has a team

        const descriptions = await fetchPoapDescriptions();
        setPoapDescriptions(descriptions);

        if (userTeamId) {
          const statusPromises = descriptions.map(async (poap) => {
            const teamStepStatus = await fetchTeamStepStatus(userTeamId, poap.id);
            const isComplete = teamStepStatus.every(step => step.status === 'completed');
            return { poapId: poap.id, isComplete };
          });

          const statuses = await Promise.all(statusPromises);
          const statusMap = statuses.reduce((acc, status) => {
            acc[status.poapId] = status.isComplete;
            return acc;
          }, {});
          setCompletionStatus(statusMap);
        }
      } catch (error) {
        console.error("Error fetching POAP descriptions or statuses:", error);
      }
    };

    loadPoapDescriptions();
  }, []);

  const toggleTab = (index) => {
    setToggleState(index);
  }

  const handlePoapClick = (poapId, index) => {
    const selectedPoap = poapDescriptions.find(poap => poap.id === poapId);
    if (selectedPoap) {
      navigate('/admin/poaps-desc', {
        state: { poap: selectedPoap, poapIndex: index }
      });
    }
  };

  const filteredPoapDescriptions = toggleState === 2
    ? poapDescriptions.filter(poap => completionStatus[poap.id])
    : poapDescriptions;

  const ownedCount = poapDescriptions.filter(poap => completionStatus[poap.id]).length;

  return (
    <div className="mt-3 flex h-full w-full flex-col font-dm md:gap-7 lg:flex-row">
      <div className="h-full w-full rounded-[20px]">
        {/* tabs */}
        <div className="mb-4 mt-8 md:mt-2 flex h-full w-full flex-col items-center rounded-xl bg-white/70 pl-[45px] shadow-2xl shadow-white dark:!bg-navy-900 dark:!shadow-none xl:mb-3">
          <div className="flex w-full justify-start gap-11 overflow-hidden text-3xl pt-4">
            <div
              className={
                toggleState === 1
                  ? " flex items-center gap-2 border-b-[4px] border-brand-500 pb-3 hover:cursor-pointer dark:border-brand-400"
                  : "flex items-center gap-2 border-b-[4px] border-white pb-3 hover:cursor-pointer dark:!border-navy-900"
              }
              onClick={() => toggleTab(1)}
            >
              <h5 className="text-base font-medium text-navy-700 dark:text-white">
                All
              </h5>
              <p className="text-sm font-medium text-gray-600">{poapDescriptions.length}</p>
            </div>

            <div
              className={
                toggleState === 2
                  ? " flex items-center gap-2 border-b-[4px] border-brand-500 pb-3 hover:cursor-pointer"
                  : "flex items-center gap-2 border-b-[4px] border-white pb-3 hover:cursor-pointer dark:!border-navy-900"
              }
              onClick={() => toggleTab(2)}
            >
              <h5 className="text-base font-medium text-navy-700 dark:text-white">
                Owned
              </h5>
              <p className="text-sm font-medium text-gray-600">{ownedCount}</p>
            </div>
          </div>
        </div>

        {/* course section */}
        {filteredPoapDescriptions.length > 0 ? (
          filteredPoapDescriptions.map((poap, index) => {
            const image = getPoapImage(poap.id); // Use a function to get the correct image
            const isLocked = !completionStatus[poap.id];
            return (
              <Milestone
                key={poap.id}
                extra="mb-5"
                isLocked={isLocked}
                image={image}
                title={poap.title}
                desc={poap.description.split('.')[0]} // Displaying the first sentence of the description     
                topics={["Beginner Level", poap.projectName]}
                onClick={() => handlePoapClick(poap.id, index)}
              />
            );
          })
        ) : (
          <p className="text-base font-medium text-gray-600 dark:text-white mt-4 text-center">
            No POAP acquired yet.
          </p>
        )}
      </div>
      {/* line */}
      
      <div className="h-0 w-0 bg-gray-300 dark:!bg-navy-700 lg:h-[1050px] lg:w-px" />

      {/* right section */}
      <div className="mt-1 flex h-full w-full flex-col items-center rounded-[20px] bg-white px-4 py-4 shadow-2xl shadow-gray-100 dark:!bg-navy-800 dark:shadow-none lg:w-[275px] 3xl:w-[470px]">
        {/* Calendar */}
        <Card extra={`max-w-full`}>
          <MiniCalendar />
        </Card>
        {/* Hours */}
        <Card extra={"w-full mt-4"}>
          <Hours />
        </Card>
      </div>
    </div>
  );  

};

export default ProjectMilestones;
