import React, { useState, useEffect } from "react";
import Dropdown from "components/dropdown";
import { IoChatboxEllipses } from "react-icons/io5";
import { fetchMessages, markMessagesAsRead, getUserProfile } from "api";
import { useNavigate } from "react-router-dom";

const MessageDropdown = () => {
  const [hasNewMessages, setHasNewMessages] = useState(false);
  const [messages, setMessages] = useState([]);
  const [userProfile, setUserProfile] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const loadData = async () => {
      try {
        const [profile, fetchedMessages] = await Promise.all([
          getUserProfile(),
          fetchMessages(),
        ]);

        setUserProfile(profile.user);

        const unreadReceivedMessages = fetchedMessages.filter(
          (message) => message.receiver.id === profile.user.id && !message.isRead
        );

        setMessages(unreadReceivedMessages);
        setHasNewMessages(unreadReceivedMessages.length > 0);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load messages.");
      }
    };

    loadData();
  }, []);

  const handleMessageClick = async (message) => {
    try {
      await markMessagesAsRead([message.id]);
      setMessages((prevMessages) => prevMessages.filter((msg) => msg.id !== message.id));
      setHasNewMessages((prevMessages) => prevMessages.length > 1);
      navigate(`/messages?chatWith=${message.sender.id}`);
    } catch (error) {
      console.error("Error marking message as read:", error);
      setError("Failed to mark message as read.");
    }
  };

  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    const now = new Date();
    const isToday = date.toDateString() === now.toDateString();
    return isToday ? date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : date.toLocaleDateString();
  };

  const handleViewAllMessages = () => {
    navigate("/admin/messages");
  };

  return (
    <Dropdown
      button={
        <div className="relative cursor-pointer">
          <IoChatboxEllipses
            className={`${
              hasNewMessages ? "h-6 w-6 text-green-500" : "h-5 w-5 text-gray-600 dark:text-white"
            }`}
          />
          {hasNewMessages && (
            <span className="absolute -top-1 -right-1 flex h-3 w-3">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-3 w-3 bg-green-500"></span>
            </span>
          )}
        </div>
      }
      children={
        <div className="flex w-[350px] flex-col gap-2 rounded-[20px] bg-white p-4 shadow-xl shadow-shadow-500 dark:!bg-navy-700 dark:text-white dark:shadow-none">
          <p className="text-lg font-bold text-navy-700 dark:text-white">Messages</p>
          {error && <p className="text-sm text-red-500">{error}</p>}
          <div className="flex flex-col gap-3">
            {messages.length > 0 ? (
              messages.map((message, index) => (
                <div key={index} className="flex items-start gap-3 cursor-pointer" onClick={() => handleMessageClick(message)}>
                  <img
                    src={message.sender.imageUrl || "https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp&f=y"}
                    alt={`${message.sender.firstName} ${message.sender.lastName}`}
                    className="h-10 w-10 rounded-full"
                  />
                  <div className="flex-1">
                    <p className="text-sm font-semibold text-navy-700 dark:text-white">
                      {message.sender.firstName} {message.sender.lastName}
                    </p>
                    <p className="text-xs text-gray-600 dark:text-gray-300">
                      {message.content}
                    </p>
                    <p className="text-xs text-gray-400 dark:text-gray-500">
                      {formatTime(message.timestamp)}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-sm text-gray-600 dark:text-gray-300">No new messages</p>
            )}
          </div>
          <button
            onClick={handleViewAllMessages}
            className="mt-4 block w-full text-center text-sm font-bold text-brand-500 hover:text-brand-600 dark:text-brand-400 dark:hover:text-brand-300"
          >
            View All Messages
          </button>
        </div>
      }
      classNames={"py-2 top-6 -left-[250px] md:-left-[330px] w-max"}
      animation="origin-[75%_0%] md:origin-top-right transition-all duration-300 ease-in-out"
    />
  );
};

export default MessageDropdown;
