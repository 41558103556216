// src/api/axiosInstance.js
import axios from 'axios';
import { getToken, removeToken, isTokenExpired } from 'utils/auth';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://academy.innovatorsforge.com';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  }
});

// Interceptor to add Authorization header to each request
axiosInstance.interceptors.request.use(
  async config => {
    let token = getToken();
    if (token) {
      if (isTokenExpired(token)) {
        removeToken();
        window.location.href = '/auth/sign-in';
        return Promise.reject(new Error('Token expired'));
      } else {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
)

export default axiosInstance;
