import React from 'react';
import FullCalendar from '@fullcalendar/react'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import { useMediaQuery } from 'react-responsive';

function EventCalendar(props) {
  const { calendarData, initialDate } = props;

  // Define breakpoints
  const isSmallScreen = useMediaQuery({ query: '(max-width: 768px)' });
  const isMediumScreen = useMediaQuery({ query: '(min-width: 769px) and (max-width: 1024px)' });
  const isLargeScreen = useMediaQuery({ query: '(min-width: 1025px)' });

  const headerToolbarConfig = () => {
    if (isSmallScreen) {
      return {
        left: 'prev,next',
        center: 'title',
        right: 'today'
      };
    } else if (isMediumScreen) {
      return {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek'
      };
    } else if (isLargeScreen) {
      return {
        left: 'prev,next today',
        center: 'title',
        right: 'dayGridMonth,timeGridWeek,timeGridDay'
      };
    }
  };

  return (
    <FullCalendar
      plugins={[dayGridPlugin, interactionPlugin]}
      headerToolbar={headerToolbarConfig()}
      initialView="dayGridMonth"
      contentHeight="auto"
      events={calendarData}
      initialDate={initialDate}
      editable={true}
      height="100%"
    />
  );
}

export default EventCalendar;
